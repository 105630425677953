import { Autocomplete, Box, Button, Grid, TextField, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import DeleteIcon from '@mui/icons-material/Delete';
import Header from "../../components/Header";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useEffect, useRef, useState } from "react";
import { modulesListUrl, privilegesListUrl } from "../../constants/globalUrls";
import AddIcon from '@mui/icons-material/Add';
import axios from "axios";
import { toast } from "react-toastify";
import FormData from "./components/form_component";
import UseServices from "../../services/api"

const Modules = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const dataFetchedRef = useRef(false);
    const [currentMode, setCurrentMode] = useState("list")  //# view ,list, edit 
    const [isLoading, setIsLoading] = useState(false)
    const [result, setResult] = useState([])
    const [resultTitle, setTitleResult] = useState([])
    const [currentObj, setCurrentObj] = useState({
        "name": "", "description": "", "_id": "", "id": ""
    })

    const permissions = Object.freeze({
        add: false,
        edit: false,
        view: true, // List of data must be true for other permissions
        delete: false

    })

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        // fetchRequests();
        loadService(true);
    }, [])
    const deleteRecordNow = async (rowId) => {
        // let id = toast.loading("Please wait...")
        // const response = await UseServices.fetchPostData(deleteRolesUrl, {
        //     "id": rowId
        // });
        // const responseData = response;
        // if (response.status === 'valid') {
        //     // toast.success(responseData.message)
        //     toast.update(id, { render: responseData.message, autoClose: "3000", type: "success", isLoading: false });
        //     loadService(true);
        // } else {
        //     toast.error(responseData.message)
        // }
        // console.log(responseData.message);
    }


    const fetchRequests = async () => {
        setIsLoading(true);
        try {
            const response = await axios.post(
                privilegesListUrl,
                JSON.stringify({ access_token: localStorage.getItem("access_token") }),
                { headers: { 'Content-Type': 'application/json' } }
            );
            // console.log("response", response.status);
            setIsLoading(false);
            if (response.status === 200) {
                // console.log("response", response.data.data)
                setResult(response.data.data);
            } else {
                toast.error("Something error occured. Try again..!");
            }
        } catch (error) {
            setIsLoading(false);
            toast.error("An error occurred while submitting the form");
            console.log(error);
        }
    };

    const editFunClick = (data) => {
        // console.log(data);
        setCurrentObj(data);
        setCurrentMode("edit");
    }
    const loadService = (isLoa = false) => {
        // alert(isLoa)
        if (isLoa) {
            fetchRequests();
        }
    }
    const columns = [
        { field: "id", headerName: "ID" },
        { field: "_id", headerName: "Module Id", flex: 1 },
        { field: "module_name", headerName: "Module Name", flex: 1 },
       
        {
            field: "display_order",
            headerName: "Display Order",
            flex: 1,
        },
        { field: "parent_module_id", headerName: "Parent Module Id", flex: 1 },
        {
            field: "parent_module",
            cellClassName: 'parent_modules',
            headerName: "Sub Modules",
            flex: 1,
            renderCell: (params) => {
                if (params.row.parent_module_id === "") {
                    return (
                        <span style={{color:"yellow",padding:"10px 25px",background:"red"}}>Parent &nbsp;</span>
                    );
                } else {
                    return (
                        <span style={{color:"white",padding:"10px 25px",background:"green"}}>Children</span>
                        );
                }
            },

        },
        {
            field: "actions",
            headerName: "Actions",
            headerAlign: "center",
            align: "center",
            flex: 1,
            renderCell: (params) => {
                return (
                    <>

                        {permissions.edit && <>
                            <Button onClick={() => editFunClick(params.row)} variant="contained" color="success" ><BorderColorIcon /></Button>
                        </>}
                        &nbsp;&nbsp;
                        {permissions.delete && <>
                            <Button onClick={() => {
                                if (
                                    window.confirm(
                                        'Are you sure Delete this "' +
                                        params.row.name +
                                        '" Support Request?'
                                    )
                                ) {
                                    deleteRecordNow(
                                        params.row._id
                                    );
                                }
                            }} variant="contained" color="error">
                                <DeleteIcon />
                            </Button>
                        </>}

                    </>

                );
            },
        },
    ];

    return (
        <>
            {/* <pre style={{ color: "#fff" }}>
                    {JSON.stringify(currentObj, null, 2)}
                  </pre> */}
            {(currentMode === "edit" || currentMode === "view") && <>
                <Box m="20px">
                    <Grid container spacing={2}>
                        <Grid item xs={7}>
                            <Header title="Edit Roles Page" subtitle="View / Edit the Roles  " />
                        </Grid>
                        <Grid item xs={5}>
                        </Grid>


                    </Grid>
                    <Box
                        m="0px 0 0 0"
                        height="70vh"
                    >
                        <FormData editPermission={permissions.edit} loadService={loadService} currentObj={currentObj} modeSetUp={setCurrentMode} />
                    </Box>
                </Box>
            </>}
            {currentMode === "list" &&
                <Box m="20px">
                    <Grid container spacing={1}>
                        <Grid item xs={11}>
                            <Header title="Roles " subtitle="Managing the Roles List" />
                        </Grid>
                        <Grid item xs={1}>

                            {permissions.add && <>
                                <Button onClick={() => {
                                    setCurrentMode("view");
                                    setCurrentObj({});
                                }} startIcon={<AddIcon />} variant="outlined" color="success"> Add </Button>
                            </>}

                        </Grid>


                    </Grid>
                    <Box
                        m="0px 0 0 0"
                        height="70vh"
                        sx={{
                            "& .MuiDataGrid-root": {
                                border: "none",
                            },
                            "& .MuiDataGrid-cell": {
                                borderBottom: "none",
                            },
                            "& .name-column--cell": {
                                color: colors.greenAccent[300],
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: colors.blueAccent[700],
                                borderBottom: "none",
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                backgroundColor: colors.primary[400],
                            },
                            "& .MuiDataGrid-footerContainer": {
                                borderTop: "none",
                                backgroundColor: colors.blueAccent[700],
                            },
                            "& .MuiCheckbox-root": {
                                color: `${colors.greenAccent[200]} !important`,
                            },
                            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                color: `${colors.grey[100]} !important`,
                            },
                            '& .MuiCircularProgress-root': {
                                color: 'green',
                            },
                        }}
                    >
                        <DataGrid loading={isLoading} rows={result} columns={columns} density={"compact"}
                            components={{ Toolbar: GridToolbar }} />
                    </Box>
                </Box>}
        </>


    );
}


export default Modules