import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export default function Error() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        minHeight: "100vh",
        bgcolor: "black",
      }}
    >
      <Typography variant="h1" sx={{ color: "white", fontSize: "8rem" }}>
        404
      </Typography>
      <Typography variant="h6" sx={{ color: "white", fontSize: "2rem" }}>
        The page you’re looking for doesn’t exist.
      </Typography>

      <Button
        variant="contained"
        sx={{
          borderRadius: 50,
          color: "#fff",
          fontWeight: 700,
          height: 48,
          padding: "0 30px",
          my: 2,
          boxShadow: "none",
          backgroundImage: "linear-gradient(to right, #FF416C, #FF4B2B)",
          "&:hover": {
            backgroundImage: "linear-gradient(to right, #FF4B2B, #FF416C)",
            textDecoration: "none",
          },
        }}
        component={Link}
        to="/dashboard"
      >
        Back To Home
      </Button>
    </Box>
  );
}
