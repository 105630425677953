import { Box, Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import { customersDetailsUrl } from "../../../constants/globalUrls";
import { Navigate, useNavigate } from "react-router-dom";
const CustomerDetails = () => {
  const navigate= useNavigate()
  const [result, setResult] = useState({});
  // payments_details
  const queryParams = new URLSearchParams(window.location.search);
  const projectId = queryParams.get("project_id");
  // console.log("projectId : " + projectId);

  useEffect(() => {
    getCustomerDetails();
  }, []);

  const getCustomerDetails = async () => {
    await axios
      .post(
        customersDetailsUrl,
        JSON.stringify({
          customer_id: projectId,
        }),
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((res) => {
        let result = res["data"]["data"];
        // console.log("cusdet", result);
        setResult(result);
      })

      .catch((error) => {
        // setIsLoading(false);
        console.log(error);
      });
  };

  return (
    <Box
      sx={{
        width: "100%",
        paddingRight: "32px",
        paddingLeft: "32px",
        marginRight: "auto",
        marginLeft: "auto",
        marginBottom: "100px",
      }}
    >
      <Box  >
        <Grid container justify="center" spacing={0}>
          <Grid item lg={4} xs={12}>
            <h2>Selected Customer Details</h2>
          </Grid>
          <Grid item lg={6} xs={12}>
          </Grid>
          <Grid sx={{marginTop: "20px"}} item lg={2} xs={12}>
          <Button type="button" 
          onClick={() => navigate("/customers")}
           color="info" variant="contained">
                            Back
                        </Button> &nbsp;&nbsp;
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Box
          sx={{
            color: "#FFFFFF",
            backgroundColor: "#68B90B",
            paddingTop: "8px",
            paddingBottom: "8px",
          }}
        >
          <Grid container spacing={0}>
            <Grid sx={{ paddingLeft: "10px" }} item lg={4} xs={12}>
              Title
            </Grid>
            <Grid item lg={4} xs={12}>
              Details
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Grid container spacing={0}>
            <Grid sx={{ paddingLeft: "10px" }} item lg={4} xs={12}>
              <p>Customer ID</p>
            </Grid>
            <Grid item lg={3} xs={12}>
              <p>{projectId}</p>
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Grid container spacing={0}>
            <Grid sx={{ paddingLeft: "10px" }} item lg={4} xs={12}>
              <p>Appleation</p>
            </Grid>
            <Grid item lg={3} xs={12}>
              <p>{result.appleation}</p>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Grid container spacing={0}>
            <Grid sx={{ paddingLeft: "10px" }} item lg={4} xs={12}>
              <p>First Name</p>
            </Grid>
            <Grid item lg={3} xs={12}>
              <p>{result?.first_name}</p>
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Grid container spacing={0}>
            <Grid sx={{ paddingLeft: "10px" }} item lg={4} xs={12}>
              <p>Middle Name</p>
            </Grid>
            <Grid item lg={3} xs={12}>
              <p>{result.middle_name}</p>
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Grid container spacing={0}>
            <Grid sx={{ paddingLeft: "10px" }} item lg={4} xs={12}>
              <p>Last Name</p>
            </Grid>
            <Grid item lg={3} xs={12}>
              <p>{result.last_name}</p>
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Grid container spacing={0}>
            <Grid sx={{ paddingLeft: "10px" }} item lg={4} xs={12}>
              <p>Username</p>
            </Grid>
            <Grid item lg={3} xs={12}>
              <p>{result.username}</p>
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Grid container spacing={0}>
            <Grid sx={{ paddingLeft: "10px" }} item lg={4} xs={12}>
              <p>City</p>
            </Grid>
            <Grid item lg={4} xs={12}>
              <p>{result.city}</p>
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Grid container spacing={0}>
            <Grid sx={{ paddingLeft: "10px" }} item lg={4} xs={12}>
              <p>Mobile Number</p>
            </Grid>
            <Grid item lg={4} xs={12}>
              <p>{result.mobile}</p>
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Grid container spacing={0}>
            <Grid sx={{ paddingLeft: "10px" }} item lg={4} xs={12}>
              <p>Designation</p>
            </Grid>
            <Grid item lg={4} xs={12}>
              <p>{result.designation}</p>
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Grid container spacing={0}>
            <Grid sx={{ paddingLeft: "10px" }} item lg={4} xs={12}>
              <p>Email</p>
            </Grid>
            <Grid item lg={4} xs={12}>
              <p>{result.email}</p>
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Grid container spacing={0}>
            <Grid sx={{ paddingLeft: "10px" }} item lg={4} xs={12}>
              <p>Alternative Email</p>
            </Grid>
            <Grid item lg={4} xs={12}>
              <p>{result.alternative_email}</p>
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Grid container spacing={0}>
            <Grid sx={{ paddingLeft: "10px" }} item lg={4} xs={12}>
              <p>Customer Status</p>
            </Grid>
            <Grid item lg={4} xs={12}>
              <p>{result.active_status}</p>
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Grid container spacing={0}>
            <Grid sx={{ paddingLeft: "10px" }} item lg={4} xs={12}>
              <p>User Type</p>
            </Grid>
            <Grid item lg={4} xs={12}>
              <p>{result.user_type}</p>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default CustomerDetails;
