import { useEffect, useRef, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useTheme,
} from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { reportsAndStatsUrl,packageCategoriesListUrl } from "../../constants/globalUrls";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import axios from "axios";
import { toast } from "react-toastify";
import BarChart from "./components/barchart";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

const Reports = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dataFetchedRef = useRef(false);
  const [currentMode, setCurrentMode] = useState("list"); //# view ,list, edit
  const [isLoading, setIsLoading] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [pakcageCategoriesList, setPakcageCategoriesList] = useState([]);
  const [formData, setFormData] = useState({
    from_date: "",
    to_date: "",
    category_id: "",
  });

  const getOptionLabel = (option) => {
    return option.label;
  };

  const isOptionEqualToValue = (option, value) => {
    return option.year === value.year && option.label === value.label;
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  function formatDate(date) {
    if (!date) return ""; // Handle case when no date is selected
    const formattedDate = new Date(date);
    const year = formattedDate.getFullYear();
    const month = (formattedDate.getMonth() + 1).toString().padStart(2, "0");
    const day = formattedDate.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const handleInputChangeFromDates = (date) => {
    const formattedDate = formatDate(date);
    setFormData((prevFormData) => ({
      ...prevFormData,
      from_date: formattedDate,
    }));
  };

  const handleInputChangeToDates = (date) => {
    const formattedDate = formatDate(date);
    setFormData((prevFormData) => ({
      ...prevFormData,
      to_date: formattedDate,
    }));
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    loadService(true);
  }, []);

  const loadService = (isLoa = false) => {
    if (isLoa) {
      fetchCategories();
      fetchPackageCategoriesList();
    }
  };
  const fetchPackageCategoriesList = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        packageCategoriesListUrl,
        JSON.stringify({
          formData,
        }),
        { headers: { "Content-Type": "application/json" } }
      );
      setIsLoading(false);
        // console.log(response.data);
      if (response.data.status === "valid") {
        const result = response?.data?.data;
        if (result) {
          setPakcageCategoriesList(result);
          }
      } else {
          toast.error("Something error occured. Try again..!");
        }
      } catch (error) {
        setIsLoading(false);
        toast.error("An error occurred while submitting the form");
        console.log(error);
      }
    };  
  const fetchCategories = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        reportsAndStatsUrl,formData,
        { headers: { "Content-Type": "application/json" } }
      );
      setIsLoading(false);
      if (response.data.status === "valid") {
        const result = response?.data?.data?.data;
        if (result) {
          setChartData(result);
          // console.log("result:::: ",result);
          }
      } else {
          toast.error("Something error occured. Try again..!");
        }
      } catch (error) {
        setIsLoading(false);
        toast.error("An error occurred while submitting the form");
        console.log(error);
      }
    };

    return (
      <>
      
        {currentMode === "list" && (
          <Box m="20px">
            <Grid container justify="center" spacing={0}>
              <Grid item lg={4} xs={12}>
                <Header
                  title="Reports and Statistics"
                  subtitle="Managing the Reports and Statistics"
                />
              </Grid>
            </Grid>
            <Grid
              sx={{ marginBottom: "80px" }}
              container
              justify="center"
              spacing={0}
            >
              <Grid>
                <InputLabel htmlFor="status">From Date</InputLabel>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker onChange={handleInputChangeFromDates}>
                      {({ inputRef, inputProps, RenderInput }) => (
                        <TextField
                          {...inputProps}
                          inputRef={inputRef}
                          name="from_date"
                          value={formData.from_date}
                          sx={{
                            height: "55px",
                            borderBottom: "1px solid #fff",
                            gridColumn: "span 4",
                            background: "#171717",
                            width: "100%",
                          }}
                          label="From Date"
                          InputLabelProps={{
                            style: { color: "white" },
                          }}
                          // InputProps={{
                          //   ...inputProps,
                          // }}
                          InputProps={{
                            startAdornment: (
                              <>
                                <RenderInput />
                              </>
                            ),
                          }}
                        />
                      )}
                    </DatePicker>
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>

              <Grid>
                <InputLabel htmlFor="status">To Date</InputLabel>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker onChange={handleInputChangeToDates}>
                      {({ inputRef, inputProps, RenderInput }) => (
                        <TextField
                          {...inputProps}
                          inputRef={inputRef}
                          name="to_date"
                          value={formData.to_date}
                          sx={{
                            height: "55px",
                            borderBottom: "1px solid #fff",
                            gridColumn: "span 4",
                            background: "#171717",
                            width: "100%",
                          }}
                          label="From Date"
                          InputLabelProps={{
                            style: { color: "white" },
                          }}
                          InputProps={{
                            startAdornment: (
                              <>
                                <RenderInput />
                              </>
                            ),
                          }}
                        />
                      )}
                    </DatePicker>
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>

              <Grid sx={{ marginTop: "5px" }} item lg={3}>
              <InputLabel htmlFor="status">Select Category</InputLabel>
              <Select
                defaultValue={"Select Package Category"}
                value={formData.category_id}
                onChange={handleInputChange} 
                sx={{
                    width:"100%",
                borderBottom: "1px solid #fff",
                gridColumn: "span 4",
                background:"#171717"
                }} 
                name="category_id"
                label="Select Package Category"
            >
                <MenuItem value="" selected>Select Package Category</MenuItem>
                {pakcageCategoriesList &&  pakcageCategoriesList.map((cat) => {
                    return <MenuItem key={cat._id} value={cat._id}>{cat.title}</MenuItem>;
                    })}
            </Select>
              </Grid>

              <Grid item mt={2.5} p={1}>
                <Button
                  style={{ padding: "14px  10px", margin: "0px" }}
                  onClick={() => loadService(true)}
                  variant="contained"
                  color="success"
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  Submit&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Button>
                &nbsp;
              </Grid>
            </Grid>

            <Box
              m="0px 0 0 0"
              height="55vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .name-column--cell": {
                  color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.redAccent} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${colors.grey[100]} !important`,
                },
                "& .MuiCircularProgress-root": {
                  color: "green",
                },
              }}
            >
              <BarChart chartData={chartData}  />
            </Box>
          </Box>
        )}
      </> 
    );
  };

  export default Reports; 

