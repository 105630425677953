import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

export default function RedrawRadar({ data }) {
  // console.log("RedrawRadar data:::::", data);

  const svgRef = useRef(null);

  useEffect(() => {
    const monthColors = [
      "#f9bec7",
      "#ff99ac",
      "#ea698b",
      "#d55d92",
      "#c05299",
      "#ac46a1",
      "#973aa8",
      "#822faf",
      "#6d23b6",
      "#6411ad",
      "#571089",
      "#47126b",
    ];

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const directions = {
      NE: -135,
      E: -90,
      SE: -45,
      S: 0,
      SW: 45,
      W: 90,
      NW: 135,
      N: 180,
    };

    const directionColors = [
      "#f9bec7",
      "#ff99ac",
      "#ea698b",
      "#d55d92",
      "#c05299",
      "#ac46a1",
      "#973aa8",
      "#822faf",
      "#6d23b6",
      "#6411ad",
      "#571089",
      "#47126b",
    ];

    // set the dimensions and margins of the graph
    var margin = { top: 10, right: 30, bottom: 30, left: 60 },
      width = 700 - margin.left - margin.right,
      height = 700 - margin.top - margin.bottom;

    const svg2 = d3
      .select(svgRef.current)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    const getX = (r, angle) => Math.cos(angle) * r;
    const getY = (r, angle) => Math.sin(angle) * r;

    const transposeMonthlyToDirect = (data) => {
      let res = [];

      for (let i = 0; i < 9; i++) {
        res = [...res, data.map((d) => Object.values(d)[i])];
      }

      return res;
    };

    let topValue = 300;
    let domain = [0, 50, 100, 150, 200, 250, 300];

    let circularAxis = svg2
      .append("g")
      .selectAll(".circleAxis")
      .data(domain)
      .enter();

    circularAxis
      .append("circle")
      .attr("cx", width / 2)
      .attr("cy", height / 2)
      .attr("r", (d) => parseFloat(d))
      .attr("fill", (d, i) => {
        if (i === 0) {
          //   return "rgba(80, 48, 101,0.14285714285714285)"
          return "#0eb3eb"; // Specify the color for the innermost circle
        } else {
          // rgba(80, 48, 101,0.14285714285714285)
          return "rgba(95, 86, 101," + 2 / domain.length + ")"; // Specify the color for other circles
        }
      });
    //   .attr("fill", (d, i) => monthColors[i])
    //   .attr("fill", "#003344");
    //   .attr("fill", (d) => "rgba(147, 225, 216," + 1 / domain.length + ")");

    let radialAxis = svg2
      .append("g")
      .selectAll(".radAxis")
      .data(Object.values(directions))
      .enter();

    radialAxis
      .append("line")
      .attr("x1", width / 2)
      .attr("y1", height / 2)
      .attr(
        "x2",
        (d) => width / 2 + getX(topValue, (d * Math.PI) / 180 + Math.PI / 2)
      )
      .attr(
        "y2",
        (d) => height / 2 + getY(topValue, (d * Math.PI) / 180 + Math.PI / 2)
      )
    //   .attr("stroke", "#fff")
      .attr("stroke", "rgba(255, 255, 255, 0.082)")
      .attr("stroke-width", 2);

    radialAxis
      .append("text")
      .attr("class", "direction-label")
      //   .style("fill", (d) => d.color)
      .style("fill", (d, i) => directionColors[i])
      .style("font-weight", "bold")
      .text((_d, id) => Object.keys(directions)[id])
      .attr(
        "x",
        (d) =>
          width / 2 + getX(topValue + 20, (d * Math.PI) / 180 + Math.PI / 2)
      )
      .attr(
        "y",
        (d) =>
          height / 2 + getY(topValue + 20, (d * Math.PI) / 180 + Math.PI / 2)
      )
      .attr("text-anchor", function (d, i) {
        if (i < 8 && i > 4) {
          return "end";
        } else if (i === 0 || i === 4) {
          return "middle";
        } else {
          return "start";
        }
      });

    let refined = [];
    data.forEach(
      (m) => (refined = [...refined, Object.values(m).slice(0, -1)])
    );

    refined.forEach((m, id) => {
      svg2
        .append("g")
        .classed("loop", true)
        .selectAll("dot")
        .data(m)
        .enter()
        .append("circle")
        .attr(
          "cx",
          (d, id2) =>
            width / 2 +
            getX(
              d,
              (Object.values(directions)[id2] * Math.PI) / 180 + Math.PI / 2
            )
        )
        .attr(
          "cy",
          (d, id2) =>
            height / 2 +
            getY(
              d,
              (Object.values(directions)[id2] * Math.PI) / 180 + Math.PI / 2
            )
        )
        .attr("r", 2)
        .attr("fill", monthColors[id])
        .attr("stroke", "none");

      const overed = (ev, monthId) => {
        svg2.select(".labelMonth").text(months[monthId]);
        d3.select(ev.target).attr("fill", monthColors[monthId]);
        d3.select(ev.target).attr("opacity", 0.3);
        d3.select(ev.target).attr("stroke-width", 2);
      };

      const leaved = (ev) => {
        svg2.select(".labelMonth").text("");
        d3.select(ev.target).attr("fill", "none");
        d3.select(ev.target).attr("opacity", 1);
        d3.select(ev.target).attr("stroke-width", 1);
      };

      svg2
        .append("path")
        .datum(m)
        .classed("segmented", true)
        .attr("stroke-width", 3)
        .attr("fill", "none")
        .attr("id", id)
        .attr("stroke", monthColors[id])
        .attr(
          "d",
          d3
            .line()
            .curve(d3.curveLinearClosed)
            .x(function (d, id2) {
              return (
                width / 2 +
                getX(
                  d,
                  (Object.values(directions)[id2] * Math.PI) / 180 + Math.PI / 2
                )
              );
            })
            .y(function (d, id2) {
              return (
                height / 2 +
                getY(
                  d,
                  (Object.values(directions)[id2] * Math.PI) / 180 + Math.PI / 2
                )
              );
            })
        )
        .each(function (d) {
          d.path = this;
        })
        .on("mouseover", (ev) => overed(ev, id))
        .on("mouseleave", (ev) => leaved(ev));
    });

    svg2
      .append("text")
      .classed("labelMonth", true)
      .text("")
      .attr("x", width / 2)
      .attr("y", height / 2)
      .attr("fill", "white");
  }, []);
  return (
    <div>
      <div id="my_dataradar" ref={svgRef}></div>
      <div id="legend-container"></div>
      {/* <div id="loader" class="loader"></div> */}
    </div>
  );
}
