import { Box, IconButton, useTheme } from "@mui/material";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { Logout } from "@mui/icons-material";
import { Link } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { gordLogo } from "../../constants/globalUrls";

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      {/* SEARCH BAR */}
      <Box display="flex" borderRadius="3px">
        {/* <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton> */}
      </Box>

      {/* ICONS */}
      <Box display="flex">
        <img src={gordLogo} width={"110px"} />
      </Box>
      <Box display="flex">
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon sx={{ color: theme.palette.text.primary }} />
          )}
        </IconButton>

        <Link to="/dashboard">
          <IconButton>
            <HomeOutlinedIcon sx={{ color: theme.palette.text.primary }} />
          </IconButton>
        </Link>
        <IconButton>
          <NotificationsOutlinedIcon sx={{ color: theme.palette.text.primary }} />
        </IconButton>
        <IconButton>
          <SettingsOutlinedIcon sx={{ color: theme.palette.text.primary }} />
        </IconButton>
        <Link to="/profile">
          <IconButton>
            <PersonOutlinedIcon sx={{ color: theme.palette.text.primary }} />
          </IconButton>
        </Link>
        <Link to="/logout">
          <IconButton>
            <Logout sx={{ color: theme.palette.text.primary }} />
          </IconButton>
        </Link>
      </Box>
    </Box>
  );
};

export default Topbar;
