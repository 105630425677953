///LOGOS
import Mainlogo from "../assets/images/logo.png";
import GordLogo from "../assets/images/logo-1.png";
// import Favicon from "../../public/assets/images/favicon.png";

 
 
// export const BaseURL = "https://apis.arcadia-suite.com/";   
// export const BaseURL = "http://127.0.0.1:5000/"; 
// export const BaseURL = "https://apis.arcadia-suite.com/"; 
const url = new URL(window.location.href)
// alert(url.hostname); 
const  getBaseUrl=  () => { 
    let baseurl = ""
    if(url.hostname === 'admin.arcadia-suite.com'){
        // alert("LIVE")
        baseurl = "https://apis.arcadia-suite.com/";
    }else{
        // alert("STA")
        baseurl = "https://staging.apis.arcadia-suite.com/";
    }
    return baseurl;
}
export const  BaseURL = getBaseUrl();
// alert(BaseURL);


export const mergeBase = (endPoint)=>{
    return  BaseURL+endPoint;
}

export const adminLoginUrl = mergeBase("admin/auth/login");


export const adminProfileDetails = mergeBase("admin/auth/details");



export const countriesList = mergeBase("countries");
export const locationsListUrl = mergeBase("cities");
export const uploadFileOrImage = mergeBase("upload");




export const cmsListUrl = mergeBase("admin/cms");
export const cmsAddAndUpdateUrl = mergeBase("admin/cms/add_update");
export const cmsDeleteUrl =mergeBase("admin/cms/delete");

export const customersListUrl =mergeBase("admin/subscriptions/allcustomers"); // admin/customers
export const customersAddUpdateUrl =mergeBase("admin/customers/update");
export const customersDetailsUrl =mergeBase("admin/subscriptions/customers_details"); 
export const customersDeleteUrl =mergeBase("admin/customers/delete");



export const titlePackagesListUrl =mergeBase("admin/subscriptions/list");
export const subTitleListUrl =mergeBase("admin/subscriptions/all");
export const packagesListUrl =mergeBase("admin/subscriptions/packages");
export const singlePackageUrl =mergeBase("admin/subscriptions/single");
export const allPackagesListUrl =mergeBase("admin/subscriptions/all");
export const addPackagesUrl =mergeBase("admin/subscriptions/create");
export const deletePackagesUrl =mergeBase("admin/subscriptions/delete"); 
///////
export const packageCategoriesListUrl =mergeBase("admin/package_categories/list"); // admin/packageCategories
export const packageCategoriesAddUpdateUrl =mergeBase("admin/package_categories/add_update");
export const packageCategoriesDeleteUrl =mergeBase("admin/package_categories/delete");
//////
export const packageSubCategoriesListUrl =mergeBase("admin/package_sub_categories/list"); // admin/packageCategories
export const packageSubCategoriesAddUpdateUrl =mergeBase("admin/package_sub_categories/add_update");
export const packageSubCategoriesDeleteUrl =mergeBase("admin/package_sub_categories/delete");
//////
export const cmsPagesListUrl =mergeBase("admin/cms/get");
export const addUpdateCmsPagesUrl =mergeBase("admin/cms/add_update");
export const deleteCmsPagesUrl =mergeBase("admin/cms/delete");


export const faqListUrl =mergeBase("admin/faqs/list");
export const addUpdateFaqUrl =mergeBase("admin/faqs/add_update");
export const deleteFaqUrl =mergeBase("admin/faqs/delete");

export const staffListUrl =mergeBase("admin/staff/list");
export const addUpdateStaffUrl =mergeBase("admin/staff/add_update");
export const deleteStaffUrl =mergeBase("admin/staff/delete");


export const rolesListUrl =mergeBase("admin/roles/list");
export const addUpdateRolesUrl =mergeBase("admin/roles/add_update");
export const deleteRolesUrl =mergeBase("admin/roles/delete");

export const supportListUrl =mergeBase("admin/support/list");
export const addUpdateSupportUrl =mergeBase("admin/support/add_update");
export const deleteSupportUrl =mergeBase("admin/support/delete");

export const feedbackListUrl =mergeBase("admin/feedback/list");
export const addUpdatefeedbackUrl =mergeBase("admin/feedback/add_update");
export const deletefeedbackUrl =mergeBase("admin/feedback/delete");
export const projectListUrl =mergeBase("admin/project/list"); 
export const paymentsListUrl =mergeBase("admin/project/payments_list"); 
export const paymentsSuccessUrl =mergeBase("payments/make_payment_success"); 
export const paymentsDetailsUrl =mergeBase("admin/project/payments_details"); 
export const projectDetailsUrl =mergeBase("admin/project/details"); 
export const deleteProjectListUrl =mergeBase("admin/project/delete"); 

export const Defaultlogo = Mainlogo;
export const gordLogo = GordLogo;
// export const favicon = Favicon;
export const dashboardStatusUrl =mergeBase("admin/dashboard/stats"); 
export const modulesListUrl =mergeBase("admin/modules/list");
export const privilegesListUrl =mergeBase("admin/modules/get_all_roles_privilages");
export const privilagesUpdateUrl =mergeBase("admin/modules/update_privileges");
export const privilagesPermissionCheckerUrl =mergeBase("admin/modules/permission_checker");


export const landingDetailsUrl =mergeBase("admin/landingpage/details");
export const landingUpdateUrl =mergeBase("admin/landingpage/add_update");


export const landingFeaturesListUrl =mergeBase("admin/landingpage/features_list");
export const landingFeaturesAddUpdateUrl =mergeBase("admin/landingpage/features_add_update");
export const landingDeletefeaturesUrl =mergeBase("admin/landingpage/features_delete");


export const landingProjectListUrl =mergeBase("admin/landingpage/projects_list");
export const addUpdateLandingProjectUrl =mergeBase("admin/landingpage/projects_add_update");
export const deleteLandingProjectUrl =mergeBase("admin/landingpage/projects_delete");

export const siteSettingsUrl = mergeBase('user/site_settings')
export const siteSettingsUpdateUrl = mergeBase('user/update_site_settings')
export const newsLettersEmailListUrl =mergeBase("admin/landingpage/newsletters_emails");
export const newsLettersEmailDeleteUrl =mergeBase("admin/landingpage/newsletters_emails_delete");
export const contactEnquiresUrl =mergeBase("admin/landingpage/contact_enq");
export const paymentSettingsListUrl =mergeBase("admin/payment_gateway/list"); 
export const paymentSettingsAddUpdateUrl =mergeBase("admin/payment_gateway/add_update"); 
export const deletepaymentSettingsUrl =mergeBase("admin/payment_gateway/delete"); 
export const deletePaymentTransactionUrl =mergeBase("payments/delete_transaction"); 



export const reportsAndStatsUrl =mergeBase("admin/reports/graph_report"); 
export const climateJSONUrl =mergeBase("admin/dashboard/climate_json"); 



