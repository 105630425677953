import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import { tokens } from "../../../theme";
import Header from "../../../components/Header";
import { BaseURL, projectDetailsUrl } from "../../../constants/globalUrls";
import OperationPhaseDetails from "./OperationPhaseDetails";
import { useNavigate } from "react-router-dom";

const ViewProjectDetails = () => {
  const navigate = useNavigate();
  const dimensions = "kW";
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [formData, setFormData] = useState({
    no_of_fans: 10,
    from_date: "",
    to_date: "",
    current_stauts: "",
    search_key: "",
  });

  const queryParams = new URLSearchParams(window.location.search);
  const projectId = queryParams.get("project_id");

  useEffect(() => {
    getProjectDetails();
  }, []);

  const getProjectDetails = async () => {
    await axios
      .post(projectDetailsUrl, JSON.stringify({ project_id: projectId }), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        let result = res["data"]["data"];
        setFormData(result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Box m="20px">
        <Grid container justify="center" spacing={0}>
          <Grid item lg={10} xs={12}>
            <Header title="Project Details" />
          </Grid>
          <Grid
            sx={{ marginTop: "20px", textAlign: "right" }}
            item
            lg={2}
            xs={12}
          >
            <Button
              type="button"
              onClick={() => navigate("/projects")}
              color="info"
              variant="contained"
            >
              Back
            </Button>
            &nbsp;&nbsp;
          </Grid>
        </Grid>
      </Box>

      <div className="row">
        <div className="col-lg-12">
          <div
            style={{
              textAlign: "center",
              color: "#FFFF00",
              backgroundColor: "#68B90B",
              padding: "10px",
              fontSize: "18px",
              marginBottom: "20px",
            }}
          >
            Project Information
          </div>
        </div>
      </div>
      <div className="row">
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell style={{ textAlign: "right" }}>
                    Project Reference ID
                  </TableCell>
                  <TableCell>{formData._id}</TableCell>
                  <TableCell style={{ textAlign: "right" }}>Date</TableCell>
                  <TableCell>{formData.project_date}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ textAlign: "right" }}>
                    GSAS Project ID
                  </TableCell>
                  <TableCell>{formData.gsas_project_id}</TableCell>
                  <TableCell style={{ textAlign: "right" }}>
                    Project Name
                  </TableCell>
                  <TableCell>{formData.project_name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ textAlign: "right" }}>
                    Consultant Name
                  </TableCell>
                  <TableCell>{formData.consultant_name}</TableCell>
                  <TableCell style={{ textAlign: "right" }}>
                    Project Phase
                  </TableCell>
                  <TableCell>{formData.project_phase}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ textAlign: "right" }}>Country</TableCell>
                  <TableCell>{formData.country_id}</TableCell>
                  <TableCell style={{ textAlign: "right" }}>City</TableCell>
                  <TableCell>{formData.city_id}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      </div>

      <Box>
        <>
          {formData.project_phase !== "As Operated" && (
            <>
              <Box
                sx={{
                  textAlign: "center",
                  color: "#FFFF00",
                  backgroundColor: "#68B90B",
                  padding: "10px",
                  fontSize: "18px",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                Chillers Information
              </Box>

              <TableContainer component={Paper}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ textAlign: "right" }}>
                        No of Chillers
                      </TableCell>
                      <TableCell>{formData.no_of_chillers}</TableCell>
                      <TableCell style={{ textAlign: "right" }}>
                        If Capacity Is Lower Than Minimum Will The Chiller Start
                      </TableCell>
                      <TableCell>
                        {
                          formData.if_capacity_is_lower_than_minimum_will_the_chiller_start
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ textAlign: "right" }}>
                        Minimum Start Up Capacity
                      </TableCell>
                      <TableCell>
                        {formData.minimum_start_up_capacity}
                      </TableCell>
                      <TableCell style={{ textAlign: "right" }}>
                        Nominal Cop
                      </TableCell>
                      <TableCell>{formData.nominal_cop}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ textAlign: "right" }}>
                        Cooling Load Profile File
                      </TableCell>
                      <TableCell>
                        {formData?.cooling_load_profile_file && (
                          <>
                            <p style={{ fontWeight: "bold", color: "yellow" }}>
                              <a
                                rel="noreferrer"
                                style={{ color: "#FFFF00" }}
                                href={`${BaseURL}${formData?.cooling_load_profile_file}`}
                                target="_blank"
                              >
                                View document
                              </a>
                            </p>
                          </>
                        )}
                      </TableCell>
                      <TableCell style={{ textAlign: "right" }}>
                        Distribution Losses Type
                      </TableCell>
                      <TableCell>{formData.distribution_losses_type}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ textAlign: "right" }}>
                        Distribution Losses{" "}
                      </TableCell>
                      <TableCell>{formData.distribution_losses}</TableCell>
                      <TableCell style={{ textAlign: "right" }}>
                        Condenser Cooling Media
                      </TableCell>
                      <TableCell>{formData.condenser_cooling_media}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ textAlign: "right" }}>
                        Compressor Type
                      </TableCell>
                      <TableCell>{formData.compressor_type}</TableCell>
                      <TableCell style={{ textAlign: "right" }}>
                        Condenser Temperature
                      </TableCell>
                      <TableCell>{formData.condenser_temperature}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ textAlign: "right" }}>
                        Evaporator Temperature
                      </TableCell>
                      <TableCell>{formData.evaporator_temperature}</TableCell>
                      <TableCell style={{ textAlign: "right" }}>
                        Condenser Delta t
                      </TableCell>
                      <TableCell>{formData.condenser_delta_t}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ textAlign: "right" }}>
                        Uploaded Chiller Document
                      </TableCell>
                      <TableCell>
                        {formData?.chiller_other_document_file && (
                          <>
                            <p style={{ fontWeight: "bold", color: "yellow" }}>
                              <a
                                rel="noreferrer"
                                style={{ color: "blue" }}
                                href={`${BaseURL}${formData?.chiller_other_document_file}`}
                                target="_blank"
                              >
                                View Document
                              </a>
                            </p>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </>
      </Box>

      <Box>
        <>
          {formData.project_phase !== "As Operated" && (
            <>
              {formData.is_thermal_store_available === "yes" && (
                <>
                  <Box
                    sx={{
                      textAlign: "center",
                      color: "#FFFF00",
                      backgroundColor: "#68B90B",
                      padding: "10px",
                      fontSize: "18px",
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    Thermalstore Information
                  </Box>
                  <TableContainer component={Paper}>
                    <>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell style={{ textAlign: "right" }}>
                              Is Thermal Store Available
                            </TableCell>
                            <TableCell>
                              {formData.is_thermal_store_available}
                            </TableCell>
                            {/* <TableCell>Thermal Store Capacity KWH</TableCell> */}
                            <TableCell
                              style={{ textAlign: "right" }}
                            >{`Thermal Store Capacity ${dimensions}`}</TableCell>
                            <TableCell>
                              {formData.thermal_store_capacity_kwh}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ textAlign: "right" }}>
                              Lower Allowance Capacity In Percentage
                            </TableCell>
                            <TableCell>
                              {formData.lower_allowance_capacity_in_percentage}
                            </TableCell>
                            <TableCell style={{ textAlign: "right" }}>
                              {/* Charging Capacity KWH per hr maximum */}
                              {`Charging Capacity ${dimensions} per hr maximum`}
                            </TableCell>
                            <TableCell>
                              {formData.charging_capacity_kwh_per_hr_maximum}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ textAlign: "right" }}>
                              {/* Charging Capacity KWH per hr minimum */}
                              {`Charging Capacity ${dimensions} per hr minimum`}
                            </TableCell>
                            <TableCell>
                              {formData.charging_capacity_kwh_per_hr_minimum}
                            </TableCell>
                            <TableCell style={{ textAlign: "right" }}>
                              Maximum Daily Threshold
                            </TableCell>
                            <TableCell>
                              {formData.maximum_daily_thershold}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ textAlign: "right" }}>
                              Charging Cycles{" "}
                            </TableCell>
                            <TableCell>{formData.charging_cycles}</TableCell>
                            <TableCell style={{ textAlign: "right" }}>
                              Losses Per Day
                            </TableCell>
                            <TableCell>{formData.losses_per_day}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ textAlign: "right" }}>
                              Tes Pumps
                            </TableCell>
                            <TableCell>{formData.tes_pumps}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </>
                  </TableContainer>
                </>
              )}
            </>
          )}
        </>
      </Box>

      <Box>
        <>
          {formData.project_phase !== "As Operated" && (
            <>
              <Box
                sx={{
                  textAlign: "center",
                  color: "#FFFF00",
                  backgroundColor: "#68B90B",
                  padding: "10px",
                  fontSize: "18px",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                Pumps Information
              </Box>

              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <Box
                    sx={{
                      color: "#FFFF00",
                      fontSize: "12px",
                    }}
                  >
                    <h2>Condenser Pump</h2>
                  </Box>

                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{ color: "#FFFF00", fontSize: "14px" }}
                          >
                            Chiller Load(%)
                          </TableCell>
                          <TableCell
                            sx={{ color: "#FFFF00", fontSize: "14px" }}
                          >
                            Pump Load(%)
                          </TableCell>
                          <TableCell
                            sx={{ color: "#FFFF00", fontSize: "14px" }}
                          >
                            {/* Power Input(KW) */}
                            {`Power Input (${dimensions})`}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>100</TableCell>
                          <TableCell>
                            {formData.condenser_pump_load_100}
                          </TableCell>
                          <TableCell>
                            {formData.condenser_pump_power_input_100}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>75</TableCell>
                          <TableCell>
                            {formData.condenser_pump_load_75}
                          </TableCell>
                          <TableCell>
                            {formData.condenser_pump_power_input_75}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>50</TableCell>
                          <TableCell>
                            {formData.condenser_pump_load_50}
                          </TableCell>
                          <TableCell>
                            {formData.condenser_pump_power_input_50}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>25</TableCell>
                          <TableCell>
                            {formData.condenser_pump_load_25}
                          </TableCell>
                          <TableCell>
                            {formData.condenser_pump_power_input_25}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item sm={6}>
                  {formData.primary_pump_load_100 && (
                    <Box
                      sx={{
                        color: "#FFFF00",
                        fontSize: "12px",
                      }}
                    >
                      <h2>Primary Pump</h2>
                    </Box>
                  )}
                  <TableContainer component={Paper}>
                    {formData.primary_pump_type &&
                      formData.primary_pump_load_100 &&
                      formData.primary_pump_power_input_100 && (
                        <>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  sx={{ color: "#FFFF00", fontSize: "14px" }}
                                >
                                  Chiller Load(%)
                                </TableCell>
                                <TableCell
                                  sx={{ color: "#FFFF00", fontSize: "14px" }}
                                >
                                  Pump Load(%)
                                </TableCell>
                                <TableCell
                                  sx={{ color: "#FFFF00", fontSize: "14px" }}
                                >
                                  {/* Power Input(KW) */}
                                  {` Power Input(${dimensions})`}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell>100</TableCell>
                                <TableCell>
                                  {formData.primary_pump_load_100}
                                </TableCell>
                                <TableCell>
                                  {formData.primary_pump_power_input_100}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>75</TableCell>
                                <TableCell>
                                  {formData.primary_pump_load_75}
                                </TableCell>
                                <TableCell>
                                  {formData.primary_pump_power_input_75}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>50</TableCell>
                                <TableCell>
                                  {formData.primary_pump_load_50}
                                </TableCell>
                                <TableCell>
                                  {formData.primary_pump_power_input_50}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>25</TableCell>
                                <TableCell>
                                  {formData.primary_pump_load_25}
                                </TableCell>
                                <TableCell>
                                  {formData.primary_pump_power_input_25}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </>
                      )}
                  </TableContainer>
                </Grid>
                <Grid item sm={6}>
                  {formData.secondary_pump_load_100 && (
                    <Box
                      sx={{
                        color: "#FFFF00",
                        fontSize: "12px",
                      }}
                    >
                      <h2>Secondary Pump</h2>
                    </Box>
                  )}
                  <TableContainer component={Paper}>
                    {formData.secondary_pump_type &&
                      formData.secondary_pump_load_100 &&
                      formData.secondary_pump_power_input_100 && (
                        <>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  sx={{ color: "#FFFF00", fontSize: "14px" }}
                                >
                                  Chiller Load(%)
                                </TableCell>
                                <TableCell
                                  sx={{ color: "#FFFF00", fontSize: "14px" }}
                                >
                                  Pump Load(%)
                                </TableCell>
                                <TableCell
                                  sx={{ color: "#FFFF00", fontSize: "14px" }}
                                >
                                  {/* Power Input(KW) */}
                                  {`Power Input(${dimensions})`}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell>100</TableCell>
                                <TableCell>
                                  {formData.secondary_pump_load_100}
                                </TableCell>
                                <TableCell>
                                  {formData.secondary_pump_power_input_100}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>75</TableCell>
                                <TableCell>
                                  {formData.secondary_pump_load_75}
                                </TableCell>
                                <TableCell>
                                  {formData.secondary_pump_power_input_75}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>50</TableCell>
                                <TableCell>
                                  {formData.secondary_pump_load_50}
                                </TableCell>
                                <TableCell>
                                  {formData.secondary_pump_power_input_50}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>25</TableCell>
                                <TableCell>
                                  {formData.secondary_pump_load_25}
                                </TableCell>
                                <TableCell>
                                  {formData.secondary_pump_power_input_25}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </>
                      )}
                  </TableContainer>
                </Grid>
                <Grid item sm={6}>
                  <Box
                    sx={{
                      color: "#FFFF00",
                      fontSize: "12px",
                    }}
                  >
                    <h2>Tertiary Pump</h2>
                  </Box>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{ color: "#FFFF00", fontSize: "14px" }}
                          >
                            Chiller Load(%)
                          </TableCell>
                          <TableCell
                            sx={{ color: "#FFFF00", fontSize: "14px" }}
                          >
                            Pump Load(%)
                          </TableCell>
                          <TableCell
                            sx={{ color: "#FFFF00", fontSize: "14px" }}
                          >
                            {/* Power Input(KW) */}
                            {`Power Input(${dimensions})`}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>100</TableCell>
                          <TableCell>
                            {formData.tertiary_pump_load_100}
                          </TableCell>
                          <TableCell>
                            {formData.tertiary_pump_power_input_100}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>75</TableCell>
                          <TableCell>
                            {formData.tertiary_pump_load_75}
                          </TableCell>
                          <TableCell>
                            {formData.tertiary_pump_power_input_75}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>50</TableCell>
                          <TableCell>
                            {formData.tertiary_pump_load_50}
                          </TableCell>
                          <TableCell>
                            {formData.tertiary_pump_power_input_50}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>25</TableCell>
                          <TableCell>
                            {formData.tertiary_pump_load_25}
                          </TableCell>
                          <TableCell>
                            {formData.tertiary_pump_power_input_25}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
              {formData?.pumps_other_document_file && (
                <>
                  <p style={{ fontWeight: "bold", color: "yellow" }}>
                    <a
                      rel="noreferrer"
                      style={{ color: "blue" }}
                      href={`${BaseURL}${formData?.pumps_other_document_file}`}
                      target="_blank"
                    >
                      Uploaded Pumps Document
                    </a>
                  </p>
                </>
              )}
            </>
          )}
        </>
      </Box>

      <Box>
        <>
          {formData.project_phase !== "As Operated" && (
            <>
              <Box
                sx={{
                  textAlign: "center",
                  color: "#FFFF00",
                  backgroundColor: "#68B90B",
                  padding: "10px",
                  fontSize: "18px",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                Auxiliaries
              </Box>

              <TableContainer component={Paper}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{ width: "700px" }}>
                        {/* Other Auxiliaries (kWh) */}
                        {`Other Auxiliaries (${dimensions})`}
                      </TableCell>
                      <TableCell>{formData.other_auxiliaries_kwh}</TableCell>
                      {formData?.auxiliaries_other_document_file && (
                        <TableCell>
                          <>
                            <p style={{ fontWeight: "bold", color: "yellow" }}>
                              <a
                                rel="noreferrer"
                                style={{ color: "blue" }}
                                href={`${BaseURL}${formData?.auxiliaries_other_document_file}`}
                                target="_blank"
                              >
                                Uploaded Auxiliaries Document
                              </a>
                            </p>
                          </>
                        </TableCell>
                      )}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </>
      </Box>

      <Box>
        <>
          {formData.project_phase === "As Operated" && (
            <>
              <Box
                sx={{
                  textAlign: "center",
                  color: "#FFFF00",
                  fontSize: "16px",
                  padding: "1px",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                <h2>Operational Phase Details</h2>
              </Box>
              <OperationPhaseDetails
                dimensions={dimensions}
                formData={formData}
              />
            </>
          )}
        </>
      </Box>

      <Box>
        <>
          {formData.project_phase !== "As Operated" && (
            <>
              {formData.no_of_fans > 0 && (
                <Box
                  sx={{
                    textAlign: "center",
                    color: "#FFFF00",
                    backgroundColor: "#68B90B",
                    padding: "10px",
                    fontSize: "18px",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                >
                  Fans
                </Box>
              )}

              <Grid container spacing={2}>
                <Grid item sm={4}>
                  {formData.no_of_fans >= 1 && (
                    <Box
                      sx={{
                        color: "#FFFF00",
                        fontSize: "16px",
                        marginBottom: "15px",
                      }}
                    >
                      Fan 1
                    </Box>
                  )}
                  <TableContainer component={Paper}>
                    {formData.no_of_fans >= 1 && (
                      <>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell
                                sx={{ color: "#FFFF00", fontSize: "14px" }}
                              >
                                Chiller Load(%)
                              </TableCell>
                              <TableCell
                                sx={{ color: "#FFFF00", fontSize: "14px" }}
                              >
                                Fan Load(%)
                              </TableCell>
                              <TableCell
                                sx={{ color: "#FFFF00", fontSize: "14px" }}
                              >
                                {/* Power Input(kW) */}
                                {`Power Input(${dimensions})`}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>100</TableCell>
                              <TableCell>
                                {formData.fan1_power_load_100}
                              </TableCell>
                              <TableCell>
                                {formData.fan1_power_input_100}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>75</TableCell>
                              <TableCell>
                                {formData.fan1_power_load_75}
                              </TableCell>
                              <TableCell>
                                {formData.fan1_power_input_75}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>50</TableCell>
                              <TableCell>
                                {formData.fan1_power_load_50}
                              </TableCell>
                              <TableCell>
                                {formData.fan1_power_input_50}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>25</TableCell>
                              <TableCell>
                                {formData.fan1_power_load_25}
                              </TableCell>
                              <TableCell>
                                {formData.fan1_power_input_25}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </>
                    )}
                  </TableContainer>
                </Grid>
                <Grid item sm={4}>
                  {formData.no_of_fans >= 2 && (
                    <Box
                      sx={{
                        color: "#FFFF00",
                        fontSize: "16px",
                        marginBottom: "15px",
                      }}
                    >
                      Fan 2
                    </Box>
                  )}
                  <TableContainer component={Paper}>
                    {formData.no_of_fans >= 2 && (
                      <>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell
                                sx={{ color: "#FFFF00", fontSize: "14px" }}
                              >
                                Chiller Load(%)
                              </TableCell>
                              <TableCell
                                sx={{ color: "#FFFF00", fontSize: "14px" }}
                              >
                                Fan Load(%)
                              </TableCell>
                              <TableCell
                                sx={{ color: "#FFFF00", fontSize: "14px" }}
                              >
                                {/* Power Input(kW) */}
                                {`Power Input(${dimensions})`}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>100</TableCell>
                              <TableCell>
                                {formData.fan2_power_load_100}
                              </TableCell>
                              <TableCell>
                                {formData.fan2_power_input_100}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>75</TableCell>
                              <TableCell>
                                {formData.fan2_power_load_75}
                              </TableCell>
                              <TableCell>
                                {formData.fan2_power_input_75}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>50</TableCell>
                              <TableCell>
                                {formData.fan2_power_load_50}
                              </TableCell>
                              <TableCell>
                                {formData.fan2_power_input_50}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>25</TableCell>
                              <TableCell>
                                {formData.fan2_power_load_25}
                              </TableCell>
                              <TableCell>
                                {formData.fan2_power_input_25}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </>
                    )}
                  </TableContainer>
                </Grid>
                <Grid item sm={4}>
                  {formData.no_of_fans >= 3 && (
                    <Box
                      sx={{
                        color: "#FFFF00",
                        fontSize: "16px",
                        marginBottom: "15px",
                      }}
                    >
                      Fan 3
                    </Box>
                  )}
                  <TableContainer component={Paper}>
                    {formData.no_of_fans >= 3 && (
                      <>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell
                                sx={{ color: "#FFFF00", fontSize: "14px" }}
                              >
                                Chiller Load(%)
                              </TableCell>
                              <TableCell
                                sx={{ color: "#FFFF00", fontSize: "14px" }}
                              >
                                Fan Load(%)
                              </TableCell>
                              <TableCell
                                sx={{ color: "#FFFF00", fontSize: "14px" }}
                              >
                                {/* Power Input(kW) */}
                                {`Power Input(${dimensions})`}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>100</TableCell>
                              <TableCell>
                                {formData.fan3_power_load_100}
                              </TableCell>
                              <TableCell>
                                {formData.fan3_power_input_100}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>75</TableCell>
                              <TableCell>
                                {formData.fan3_power_load_75}
                              </TableCell>
                              <TableCell>
                                {formData.fan3_power_input_75}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>50</TableCell>
                              <TableCell>
                                {formData.fan3_power_load_50}
                              </TableCell>
                              <TableCell>
                                {formData.fan3_power_input_50}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>25</TableCell>
                              <TableCell>
                                {formData.fan3_power_load_25}
                              </TableCell>
                              <TableCell>
                                {formData.fan3_power_input_25}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </>
                    )}
                  </TableContainer>
                </Grid>
                <Grid item sm={4}>
                  {formData.no_of_fans >= 4 && (
                    <Box
                      sx={{
                        color: "#FFFF00",
                        fontSize: "16px",
                        marginBottom: "15px",
                      }}
                    >
                      Fan 4
                    </Box>
                  )}
                  <TableContainer component={Paper}>
                    {formData.no_of_fans >= 4 && (
                      <>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell
                                sx={{ color: "#FFFF00", fontSize: "14px" }}
                              >
                                Chiller Load(%)
                              </TableCell>
                              <TableCell
                                sx={{ color: "#FFFF00", fontSize: "14px" }}
                              >
                                Fan Load(%)
                              </TableCell>
                              <TableCell
                                sx={{ color: "#FFFF00", fontSize: "14px" }}
                              >
                                {/* Power Input(kW) */}
                                {`Power Input(${dimensions})`}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>100</TableCell>
                              <TableCell>
                                {formData.fan4_power_load_100}
                              </TableCell>
                              <TableCell>
                                {formData.fan4_power_input_100}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>75</TableCell>
                              <TableCell>
                                {formData.fan4_power_load_75}
                              </TableCell>
                              <TableCell>
                                {formData.fan4_power_input_75}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>50</TableCell>
                              <TableCell>
                                {formData.fan4_power_load_50}
                              </TableCell>
                              <TableCell>
                                {formData.fan4_power_input_50}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>25</TableCell>
                              <TableCell>
                                {formData.fan4_power_load_25}
                              </TableCell>
                              <TableCell>
                                {formData.fan4_power_input_25}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </>
                    )}
                  </TableContainer>
                </Grid>
                <Grid item sm={4}>
                  {formData.no_of_fans >= 5 && (
                    <Box
                      sx={{
                        color: "#FFFF00",
                        fontSize: "16px",
                        marginBottom: "15px",
                      }}
                    >
                      Fan 5
                    </Box>
                  )}
                  <TableContainer component={Paper}>
                    {formData.no_of_fans >= 5 && (
                      <>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell
                                sx={{ color: "#FFFF00", fontSize: "14px" }}
                              >
                                Chiller Load(%)
                              </TableCell>
                              <TableCell
                                sx={{ color: "#FFFF00", fontSize: "14px" }}
                              >
                                Fan Load(%)
                              </TableCell>
                              <TableCell
                                sx={{ color: "#FFFF00", fontSize: "14px" }}
                              >
                                {/* Power Input(kW) */}
                                {`Power Input(${dimensions})`}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>100</TableCell>
                              <TableCell>
                                {formData.fan5_power_load_100}
                              </TableCell>
                              <TableCell>
                                {formData.fan5_power_input_100}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>75</TableCell>
                              <TableCell>
                                {formData.fan5_power_load_75}
                              </TableCell>
                              <TableCell>
                                {formData.fan5_power_input_75}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>50</TableCell>
                              <TableCell>
                                {formData.fan5_power_load_50}
                              </TableCell>
                              <TableCell>
                                {formData.fan5_power_input_50}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>25</TableCell>
                              <TableCell>
                                {formData.fan5_power_load_25}
                              </TableCell>
                              <TableCell>
                                {formData.fan5_power_input_25}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </>
                    )}
                  </TableContainer>
                </Grid>
                <Grid item sm={4}>
                  {formData.no_of_fans >= 6 && (
                    <Box
                      sx={{
                        color: "#FFFF00",
                        fontSize: "16px",
                        marginBottom: "15px",
                      }}
                    >
                      Fan 6
                    </Box>
                  )}
                  <TableContainer component={Paper}>
                    {formData.no_of_fans >= 6 && (
                      <>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell
                                sx={{ color: "#FFFF00", fontSize: "14px" }}
                              >
                                Chiller Load(%)
                              </TableCell>
                              <TableCell
                                sx={{ color: "#FFFF00", fontSize: "14px" }}
                              >
                                Fan Load(%)
                              </TableCell>
                              <TableCell
                                sx={{ color: "#FFFF00", fontSize: "14px" }}
                              >
                                {/* Power Input(kW) */}
                                {`Power Input(${dimensions})`}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>100</TableCell>
                              <TableCell>
                                {formData.fan6_power_load_100}
                              </TableCell>
                              <TableCell>
                                {formData.fan6_power_input_100}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>75</TableCell>
                              <TableCell>
                                {formData.fan6_power_load_75}
                              </TableCell>
                              <TableCell>
                                {formData.fan6_power_input_75}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>50</TableCell>
                              <TableCell>
                                {formData.fan6_power_load_50}
                              </TableCell>
                              <TableCell>
                                {formData.fan6_power_input_50}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>25</TableCell>
                              <TableCell>
                                {formData.fan6_power_load_25}
                              </TableCell>
                              <TableCell>
                                {formData.fan6_power_input_25}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </>
                    )}
                  </TableContainer>
                </Grid>
                <Grid item sm={4}>
                  {formData.no_of_fans >= 7 && (
                    <Box
                      sx={{
                        color: "#FFFF00",
                        fontSize: "16px",
                        marginBottom: "15px",
                      }}
                    >
                      Fan 7
                    </Box>
                  )}
                  <TableContainer component={Paper}>
                    {formData.no_of_fans >= 7 && (
                      <>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell
                                sx={{ color: "#FFFF00", fontSize: "14px" }}
                              >
                                Chiller Load(%)
                              </TableCell>
                              <TableCell
                                sx={{ color: "#FFFF00", fontSize: "14px" }}
                              >
                                Fan Load(%)
                              </TableCell>
                              <TableCell
                                sx={{ color: "#FFFF00", fontSize: "14px" }}
                              >
                                {/* Power Input(kW) */}
                                {`Power Input(${dimensions})`}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>100</TableCell>
                              <TableCell>
                                {formData.fan7_power_load_100}
                              </TableCell>
                              <TableCell>
                                {formData.fan7_power_input_100}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>75</TableCell>
                              <TableCell>
                                {formData.fan7_power_load_75}
                              </TableCell>
                              <TableCell>
                                {formData.fan7_power_input_75}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>50</TableCell>
                              <TableCell>
                                {formData.fan7_power_load_50}
                              </TableCell>
                              <TableCell>
                                {formData.fan7_power_input_50}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>25</TableCell>
                              <TableCell>
                                {formData.fan7_power_load_25}
                              </TableCell>
                              <TableCell>
                                {formData.fan7_power_input_25}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </>
                    )}
                  </TableContainer>
                </Grid>
                <Grid item sm={4}>
                  {formData.no_of_fans >= 8 && (
                    <Box
                      sx={{
                        color: "#FFFF00",
                        fontSize: "16px",
                        marginBottom: "15px",
                      }}
                    >
                      Fan 8
                    </Box>
                  )}
                  <TableContainer component={Paper}>
                    {formData.no_of_fans >= 8 && (
                      <>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell
                                sx={{ color: "#FFFF00", fontSize: "14px" }}
                              >
                                Chiller Load(%)
                              </TableCell>
                              <TableCell
                                sx={{ color: "#FFFF00", fontSize: "14px" }}
                              >
                                Fan Load(%)
                              </TableCell>
                              <TableCell
                                sx={{ color: "#FFFF00", fontSize: "14px" }}
                              >
                                {/* Power Input(kW) */}
                                {`Power Input(${dimensions})`}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>100</TableCell>
                              <TableCell>
                                {formData.fan8_power_load_100}
                              </TableCell>
                              <TableCell>
                                {formData.fan8_power_input_100}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>75</TableCell>
                              <TableCell>
                                {formData.fan8_power_load_75}
                              </TableCell>
                              <TableCell>
                                {formData.fan8_power_input_75}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>50</TableCell>
                              <TableCell>
                                {formData.fan8_power_load_50}
                              </TableCell>
                              <TableCell>
                                {formData.fan8_power_input_50}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>25</TableCell>
                              <TableCell>
                                {formData.fan8_power_load_25}
                              </TableCell>
                              <TableCell>
                                {formData.fan8_power_input_25}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </>
                    )}
                  </TableContainer>
                </Grid>
                <Grid item sm={4}>
                  {formData.no_of_fans >= 9 && (
                    <Box
                      sx={{
                        color: "#FFFF00",
                        fontSize: "16px",
                        marginBottom: "15px",
                      }}
                    >
                      Fan 9
                    </Box>
                  )}
                  <TableContainer component={Paper}>
                    {formData.no_of_fans >= 9 && (
                      <>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell
                                sx={{ color: "#FFFF00", fontSize: "14px" }}
                              >
                                Chiller Load(%)
                              </TableCell>
                              <TableCell
                                sx={{ color: "#FFFF00", fontSize: "14px" }}
                              >
                                Fan Load(%)
                              </TableCell>
                              <TableCell
                                sx={{ color: "#FFFF00", fontSize: "14px" }}
                              >
                                {/* Power Input(kW) */}
                                {`Power Input(${dimensions})`}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>100</TableCell>
                              <TableCell>
                                {formData.fan9_power_load_100}
                              </TableCell>
                              <TableCell>
                                {formData.fan9_power_input_100}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>75</TableCell>
                              <TableCell>
                                {formData.fan9_power_load_75}
                              </TableCell>
                              <TableCell>
                                {formData.fan9_power_input_75}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>50</TableCell>
                              <TableCell>
                                {formData.fan9_power_load_50}
                              </TableCell>
                              <TableCell>
                                {formData.fan9_power_input_50}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>25</TableCell>
                              <TableCell>
                                {formData.fan9_power_load_25}
                              </TableCell>
                              <TableCell>
                                {formData.fan9_power_input_25}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </>
                    )}
                  </TableContainer>
                </Grid>
                <Grid item sm={4}>
                  {formData.no_of_fans >= 10 && (
                    <Box
                      sx={{
                        color: "#FFFF00",
                        fontSize: "16px",
                        marginBottom: "15px",
                      }}
                    >
                      Fan 10
                    </Box>
                  )}
                  <TableContainer component={Paper}>
                    {formData.no_of_fans >= 10 && (
                      <>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell
                                sx={{ color: "#FFFF00", fontSize: "14px" }}
                              >
                                Chiller Load(%)
                              </TableCell>
                              <TableCell
                                sx={{ color: "#FFFF00", fontSize: "14px" }}
                              >
                                Fan Load(%)
                              </TableCell>
                              <TableCell
                                sx={{ color: "#FFFF00", fontSize: "14px" }}
                              >
                                {/* Power Input(kW) */}
                                {`Power Input(${dimensions})`}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>100</TableCell>
                              <TableCell>
                                {formData.fan10_power_load_100}
                              </TableCell>
                              <TableCell>
                                {formData.fan10_power_input_100}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>75</TableCell>
                              <TableCell>
                                {formData.fan10_power_load_75}
                              </TableCell>
                              <TableCell>
                                {formData.fan10_power_input_75}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>50</TableCell>
                              <TableCell>
                                {formData.fan10_power_load_50}
                              </TableCell>
                              <TableCell>
                                {formData.fan10_power_input_50}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>25</TableCell>
                              <TableCell>
                                {formData.fan10_power_load_25}
                              </TableCell>
                              <TableCell>
                                {formData.fan10_power_input_25}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </>
                    )}
                  </TableContainer>
                </Grid>
              </Grid>
              {formData?.fans_other_document_file && (
                <>
                  <p style={{ fontWeight: "bold", color: "blue" }}>
                    <a
                      rel="noreferrer"
                      style={{ color: "blue" }}
                      href={`${BaseURL}${formData?.fans_other_document_file}`}
                      target="_blank"
                    >
                      Uploaded Fans Document
                    </a>
                  </p>
                </>
              )}
            </>
          )}
        </>
      </Box>

      <Box>
        <>
          {formData.project_phase !== "As Operated" && (
            <>
              <Box
                sx={{
                  textAlign: "center",
                  color: "#FFFF00",
                  backgroundColor: "#68B90B",
                  padding: "10px",
                  fontSize: "18px",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                Cooling Tower Information
              </Box>
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <Box
                    sx={{ color: "#FFFF00", fontSize: "14px", padding: "1px" }}
                  >
                    <h3>
                      Wet Bulb Temperature(
                      <sup>0</sup>C)
                    </h3>
                  </Box>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>Wet Bulb Temp 1</TableCell>
                          <TableCell>{formData.wet_bulb_temp_1}</TableCell>
                          <TableCell>Wet Bulb Temp 2</TableCell>
                          <TableCell>{formData.wet_bulb_temp_2}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Wet Bulb Temp 3</TableCell>
                          <TableCell>{formData.wet_bulb_temp_3}</TableCell>
                          <TableCell>Wet Bulb Temp 4</TableCell>
                          <TableCell>{formData.wet_bulb_temp_4}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Wet Bulb Temp 5</TableCell>
                          <TableCell>{formData.wet_bulb_temp_5}</TableCell>
                          <TableCell>Wet Bulb Temp 6</TableCell>
                          <TableCell>{formData.wet_bulb_temp_6}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Wet Bulb Temp 7</TableCell>
                          <TableCell>{formData.wet_bulb_temp_7}</TableCell>
                          <TableCell>Wet Bulb Temp 8</TableCell>
                          <TableCell>{formData.wet_bulb_temp_8}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Wet Bulb Temp 9</TableCell>
                          <TableCell>{formData.wet_bulb_temp_9}</TableCell>
                          <TableCell>Wet Bulb Temp 10</TableCell>
                          <TableCell>{formData.wet_bulb_temp_10}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item sm={6}>
                  <Box
                    sx={{ color: "#FFFF00", fontSize: "14px", padding: "1px" }}
                  >
                    <h3>
                      Corresponding Leaving Temperature(
                      <sup>0</sup>C)
                    </h3>
                  </Box>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            Corresponding Leaving Water Temp 1
                          </TableCell>
                          <TableCell>
                            {formData.corresponding_leaving_water_temp_1}
                          </TableCell>
                          <TableCell>
                            Corresponding Leaving Water Temp 2
                          </TableCell>
                          <TableCell>
                            {formData.corresponding_leaving_water_temp_2}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            Corresponding Leaving Water Temp 3
                          </TableCell>
                          <TableCell>
                            {formData.corresponding_leaving_water_temp_3}
                          </TableCell>
                          <TableCell>
                            Corresponding Leaving Water Temp 4
                          </TableCell>
                          <TableCell>
                            {formData.corresponding_leaving_water_temp_4}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            Corresponding Leaving Water Temp 5
                          </TableCell>
                          <TableCell>
                            {formData.corresponding_leaving_water_temp_5}
                          </TableCell>
                          <TableCell>
                            Corresponding Leaving Water Temp 6
                          </TableCell>
                          <TableCell>
                            {formData.corresponding_leaving_water_temp_6}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            Corresponding Leaving Water Temp 7
                          </TableCell>
                          <TableCell>
                            {formData.corresponding_leaving_water_temp_7}
                          </TableCell>
                          <TableCell>
                            Corresponding Leaving Water Temp 8
                          </TableCell>
                          <TableCell>
                            {formData.corresponding_leaving_water_temp_8}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            Corresponding Leaving Water Temp 9
                          </TableCell>
                          <TableCell>
                            {formData.corresponding_leaving_water_temp_9}
                          </TableCell>
                          <TableCell>
                            Corresponding Leaving Water Temp 10
                          </TableCell>
                          <TableCell>
                            {formData.corresponding_leaving_water_temp_10}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
              {formData?.cooling_tower_other_document_file && (
                <>
                  <p style={{ fontWeight: "bold", color: "yellow" }}>
                    <a
                      rel="noreferrer"
                      style={{ color: "blue" }}
                      href={`${BaseURL}${formData?.cooling_tower_other_document_file}`}
                      target="_blank"
                    >
                      Uploaded Document
                    </a>
                  </p>
                </>
              )}
            </>
          )}
        </>
      </Box>

      <Box>
        <>
          {formData.project_phase !== "As Operated" && (
            <>
              <Box
                sx={{
                  textAlign: "center",
                  color: "#FFFF00",
                  backgroundColor: "#68B90B",
                  padding: "10px",
                  fontSize: "18px",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                Co-efficient Information
              </Box>

              <TableContainer component={Paper}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>Curvature Coefficient Type</TableCell>
                      <TableCell>
                        {formData.curvature_coefficient_type}
                      </TableCell>
                      <TableCell>Temperature</TableCell>
                      <TableCell>{formData.temperature}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <Box sx={{ color: "#FFFF00", fontSize: "16px" }}>
                    <h5>Condenser Temperature</h5>
                  </Box>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>Condenser Temp 1</TableCell>
                          <TableCell>{formData.condenser_temp_1}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Condenser Temp 2</TableCell>
                          <TableCell>{formData.condenser_temp_2}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Condenser Temp 3</TableCell>
                          <TableCell>{formData.condenser_temp_3}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Condenser Temp 4</TableCell>
                          <TableCell>{formData.condenser_temp_4}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Condenser Temp 5</TableCell>
                          <TableCell>{formData.condenser_temp_5}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item sm={6}>
                  <Box sx={{ color: "#FFFF00", fontSize: "16px" }}>
                    <h5>Evaporator Chilled Water Outlet</h5>
                  </Box>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            Evaporator Chilled Water Outlet 1
                          </TableCell>
                          <TableCell>
                            {formData.evaporator_chilled_water_outlet_1}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            Evaporator Chilled Water Outlet 2
                          </TableCell>
                          <TableCell>
                            {formData.evaporator_chilled_water_outlet_2}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            Evaporator Chilled Water Outlet 3
                          </TableCell>
                          <TableCell>
                            {formData.evaporator_chilled_water_outlet_3}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            Evaporator Chilled Water Outlet 4
                          </TableCell>
                          <TableCell>
                            {formData.evaporator_chilled_water_outlet_4}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            Evaporator Chilled Water Outlet 5
                          </TableCell>
                          <TableCell>
                            {formData.evaporator_chilled_water_outlet_5}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <Box sx={{ color: "#FFFF00", fontSize: "16px" }}>
                    {/* <h5>Capacity KW</h5> */}
                    <h5> {`Capacity ${dimensions}`} </h5>
                  </Box>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>{`Capacity ${dimensions} 1`}</TableCell>
                          {/* <TableCell>Capacity KW 1</TableCell> */}
                          <TableCell>{formData.capacity_kw_1}</TableCell>
                          {/* <TableCell>Capacity KW 2</TableCell> */}
                          <TableCell>{`Capacity ${dimensions} 2`}</TableCell>
                          <TableCell>{formData.capacity_kw_2}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{`Capacity ${dimensions} 3`}</TableCell>
                          {/* <TableCell>Capacity KW 3</TableCell> */}
                          <TableCell>{formData.capacity_kw_3}</TableCell>
                          <TableCell>{`Capacity ${dimensions} 4`}</TableCell>
                          {/* <TableCell>Capacity KW 4</TableCell> */}
                          <TableCell>{formData.capacity_kw_4}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{`Capacity ${dimensions} 5`}</TableCell>
                          {/* <TableCell>Capacity KW 5</TableCell> */}
                          <TableCell>{formData.capacity_kw_5}</TableCell>
                          <TableCell>{`Capacity ${dimensions} 6`}</TableCell>
                          {/* <TableCell>Capacity KW 6</TableCell> */}
                          <TableCell>{formData.capacity_kw_6}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{`Capacity ${dimensions} 7`}</TableCell>
                          {/* <TableCell>Capacity KW 7</TableCell> */}
                          <TableCell>{formData.capacity_kw_7}</TableCell>
                          {/* <TableCell>Capacity KW 8</TableCell> */}
                          <TableCell>{`Capacity ${dimensions} 8`}</TableCell>
                          <TableCell>{formData.capacity_kw_8}</TableCell>
                        </TableRow>
                        <TableRow>
                          {/* <TableCell>Capacity KW 9</TableCell> */}
                          <TableCell>{`Capacity ${dimensions} 9`}</TableCell>
                          <TableCell>{formData.capacity_kw_9}</TableCell>
                          {/* <TableCell>Capacity KW 10</TableCell> */}
                          <TableCell>{`Capacity ${dimensions} 10`}</TableCell>
                          <TableCell>{formData.capacity_kw_10}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{`Capacity ${dimensions} 11`}</TableCell>
                          {/* <TableCell>Capacity KW 11</TableCell> */}
                          <TableCell>{formData.capacity_kw_11}</TableCell>
                          <TableCell>{`Capacity ${dimensions} 12`}</TableCell>
                          {/* <TableCell>Capacity KW 12</TableCell> */}
                          <TableCell>{formData.capacity_kw_12}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{`Capacity ${dimensions} 13`}</TableCell>
                          <TableCell>{formData.capacity_kw_13}</TableCell>
                          <TableCell>{`Capacity ${dimensions} 14`}</TableCell>
                          <TableCell>{formData.capacity_kw_14}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{`Capacity ${dimensions} 15`}</TableCell>
                          <TableCell>{formData.capacity_kw_15}</TableCell>
                          <TableCell>{`Capacity ${dimensions} 16`}</TableCell>
                          <TableCell>{formData.capacity_kw_16}</TableCell>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow>
                          <TableCell>{`Capacity ${dimensions} 17`}</TableCell>
                          <TableCell>{formData.capacity_kw_17}</TableCell>
                          <TableCell>{`Capacity ${dimensions} 18`}</TableCell>
                          <TableCell>{formData.capacity_kw_18}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{`Capacity ${dimensions} 19`}</TableCell>
                          <TableCell>{formData.capacity_kw_19}</TableCell>
                          <TableCell>{`Capacity ${dimensions} 20`}</TableCell>
                          <TableCell>{formData.capacity_kw_20}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{`Capacity ${dimensions} 21`}</TableCell>
                          <TableCell>{formData.capacity_kw_21}</TableCell>
                          <TableCell>{`Capacity ${dimensions} 22`}</TableCell>
                          <TableCell>{formData.capacity_kw_22}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{`Capacity ${dimensions} 23`}</TableCell>
                          <TableCell>{formData.capacity_kw_23}</TableCell>
                          <TableCell>{`Capacity ${dimensions} 24`}</TableCell>
                          <TableCell>{formData.capacity_kw_24}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{`Capacity ${dimensions} 25`}</TableCell>
                          <TableCell>{formData.capacity_kw_25}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item sm={6}>
                  <Box sx={{ color: "#FFFF00", fontSize: "16px" }}>
                    {/* <h5>Power Input(KW)</h5> */}
                    <h5>{`Power Input ${dimensions}`}</h5>
                  </Box>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          {/* <TableCell>Power Input KW 1</TableCell> */}
                          <TableCell>{`Power Input ${dimensions} 1`}</TableCell>
                          <TableCell>{formData.power_input_kw_1}</TableCell>
                          <TableCell>{`Power Input ${dimensions} 2`}</TableCell>
                          {/* <TableCell>Power Input KW 2</TableCell> */}
                          <TableCell>{formData.power_input_kw_2}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{`Power Input ${dimensions} 3`}</TableCell>
                          {/* <TableCell>Power Input KW 3</TableCell> */}
                          <TableCell>{formData.power_input_kw_3}</TableCell>
                          <TableCell>{`Power Input ${dimensions} 4`}</TableCell>
                          <TableCell>{formData.power_input_kw_4}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{`Power Input ${dimensions} 5`}</TableCell>
                          <TableCell>{formData.power_input_kw_5}</TableCell>
                          <TableCell>{`Power Input ${dimensions} 6`}</TableCell>
                          <TableCell>{formData.power_input_kw_6}</TableCell>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow>
                          <TableCell>{`Power Input ${dimensions} 7`}</TableCell>
                          <TableCell>{formData.power_input_kw_7}</TableCell>
                          <TableCell>{`Power Input ${dimensions} 8`}</TableCell>
                          <TableCell>{formData.power_input_kw_8}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{`Power Input ${dimensions} 9`}</TableCell>
                          <TableCell>{formData.power_input_kw_9}</TableCell>
                          <TableCell>{`Power Input ${dimensions} 10`}</TableCell>
                          <TableCell>{formData.power_input_kw_10}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{`Power Input ${dimensions} 11`}</TableCell>
                          <TableCell>{formData.power_input_kw_11}</TableCell>
                          <TableCell>{`Power Input ${dimensions} 12`}</TableCell>
                          <TableCell>{formData.power_input_kw_12}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{`Power Input ${dimensions} 13`}</TableCell>
                          <TableCell>{formData.power_input_kw_13}</TableCell>
                          <TableCell>{`Power Input ${dimensions} 14`}</TableCell>
                          <TableCell>{formData.power_input_kw_14}</TableCell>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow>
                          <TableCell>{`Power Input ${dimensions} 15`}</TableCell>
                          <TableCell>{formData.power_input_kw_15}</TableCell>
                          <TableCell>{`Power Input ${dimensions} 16`}</TableCell>
                          <TableCell>{formData.power_input_kw_16}</TableCell>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow>
                          <TableCell>{`Power Input ${dimensions} 17`}</TableCell>
                          <TableCell>{formData.power_input_kw_17}</TableCell>
                          <TableCell>{`Power Input ${dimensions} 18`}</TableCell>
                          <TableCell>{formData.power_input_kw_18}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{`Power Input ${dimensions} 19`}</TableCell>
                          <TableCell>{formData.power_input_kw_19}</TableCell>
                          <TableCell>{`Power Input ${dimensions} 20`}</TableCell>
                          <TableCell>{formData.power_input_kw_20}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{`Power Input ${dimensions} 21`}</TableCell>
                          <TableCell>{formData.power_input_kw_21}</TableCell>
                          <TableCell>{`Power Input ${dimensions} 22`}</TableCell>
                          <TableCell>{formData.power_input_kw_22}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{`Power Input ${dimensions} 23`}</TableCell>
                          <TableCell>{formData.power_input_kw_23}</TableCell>
                          <TableCell>{`Power Input ${dimensions} 24`}</TableCell>
                          <TableCell>{formData.power_input_kw_24}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{`Power Input ${dimensions} 25`}</TableCell>
                          <TableCell>{formData.power_input_kw_25}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <Box sx={{ color: "#FFFF00", fontSize: "16px" }}>
                    {/* <h5>Capacity KW</h5> */}
                    <h5> {`Capacity ${dimensions}`} </h5>
                  </Box>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>{`Capacity ${dimensions} 1`}</TableCell>
                          <TableCell>{formData.capacity_kw_1}</TableCell>
                          <TableCell>{`Capacity ${dimensions} 2`}</TableCell>
                          <TableCell>{formData.capacity_kw_2}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{`Capacity ${dimensions} 3`}</TableCell>
                          <TableCell>{formData.capacity_kw_3}</TableCell>
                          <TableCell>{`Capacity ${dimensions} 4`}</TableCell>
                          <TableCell>{formData.capacity_kw_4}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{`Capacity ${dimensions} 5`}</TableCell>
                          <TableCell>{formData.capacity_kw_5}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item sm={6}>
                  <Box sx={{ color: "#FFFF00", fontSize: "16px" }}>
                    {/* <h5>Partial Power Input (KW)</h5> */}
                    <h5> {`Partial Power Input (${dimensions})`} </h5>
                  </Box>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>{`Partial Power Input (${dimensions}) 1`}</TableCell>
                          <TableCell>
                            {formData.partial_power_input_kw_1}
                          </TableCell>
                          <TableCell>{`Partial Power Input (${dimensions}) 2`}</TableCell>
                          <TableCell>
                            {formData.partial_power_input_kw_2}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{`Partial Power Input (${dimensions}) 3`}</TableCell>
                          <TableCell>
                            {formData.partial_power_input_kw_3}
                          </TableCell>
                          <TableCell>{`Partial Power Input (${dimensions}) 4`}</TableCell>
                          <TableCell>
                            {formData.partial_power_input_kw_4}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
              {formData?.co_efficients_other_document_file && (
                <>
                  <p style={{ fontWeight: "bold", color: "blue" }}>
                    <a
                      rel="noreferrer"
                      style={{ color: "blue" }}
                      href={`${BaseURL}${formData?.co_efficients_other_document_file}`}
                      target="_blank"
                    >
                      Uploaded Document
                    </a>
                  </p>
                </>
              )}
            </>
          )}
        </>
      </Box>
    </>
  );
};

export default ViewProjectDetails;
