
import axios from 'axios';
import { toast } from 'react-toastify';
// const apiClient = axios.create({
//     baseURL: "http://43.205.20.110:5000/",
//     timeout: 5000,
//     headers: {
//       "Content-Type": "application/json"
//     },
//   });

 class UseServices  {

    async fetchPostData(url, postData) {
        try {
            const response = await axios.post(url, postData, {
                headers: { "Content-Type": "application/json" },
            }).catch(err => {
                console.log("Error " + err)
            })
            //  console.log("USE SERVICE API URL IS : " + url + "" + response.data)
            return response.data;
        } catch (error) {
            console.log(error)
            // alert("An error occurred while submitting the form.");
            toast.error("An error occurred while submitting the form.")
        }
    }

    async fetchGetData(url, getParamsData) {
        try {
          const response = await axios.get(url, {
            params: getParamsData,
            headers: { "Content-Type": "application/json" },
          }).catch(err => {
            console.log("Error " + err)
          });
          return response.data;
        } catch (error) {
          console.log(error);
          // alert("An error occurred while submitting the form.");
          toast.error("An error occurred while submitting the form.")
        }
      }

}

export default new UseServices();