import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

export default function OperationPhaseDetails({ formData, dimensions }) {
  return (
    <>
      <Box
        sx={{
          textAlign: "center",
          color: "#FFFF00",
          backgroundColor: "#68B90B",
          padding: "10px",
                  fontSize: "18px",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        Chillers Information
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell sx={{width: "700px"}}>No Of Chillers Op</TableCell>
              <TableCell>{formData.no_of_chillers_op}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Operating Hours Per Year Op</TableCell>
              <TableCell>{formData.operating_hours_per_year_op}</TableCell>
            </TableRow>
            {formData.no_of_chillers_op >= 1 && (
              <>
                <TableRow>
                  <TableCell>Capacity Of Chiller 1 Op</TableCell>
                  <TableCell>{formData.chiller_1_capacity_op}</TableCell>
                </TableRow>
              </>
            )}
            {formData.no_of_chillers_op >= 2 && (
              <>
                <TableRow>
                  <TableCell>Capacity Of Chiller 2 Op</TableCell>
                  <TableCell>{formData.chiller_2_capacity_op}</TableCell>
                </TableRow>
              </>
            )}{" "}
            {formData.no_of_chillers_op >= 3 && (
              <>
                <TableRow>
                  <TableCell>Capacity Of Chiller 3 Op</TableCell>
                  <TableCell>{formData.chiller_3_capacity_op}</TableCell>
                </TableRow>
              </>
            )}
            {formData.no_of_chillers_op >= 4 && (
              <>
                <TableRow>
                  <TableCell>Capacity Of Chiller 4 Op</TableCell>
                  <TableCell>{formData.chiller_4_capacity_op}</TableCell>
                </TableRow>
              </>
            )}
            {formData.no_of_chillers_op >= 5 && (
              <>
                <TableRow>
                  <TableCell>Capacity Of Chiller 5 Op</TableCell>
                  <TableCell>{formData.chiller_5_capacity_op}</TableCell>
                </TableRow>
              </>
            )}
            {formData.no_of_chillers_op >= 6 && (
              <>
                <TableRow>
                  <TableCell>Capacity Of Chiller 6 Op</TableCell>
                  <TableCell>{formData.chiller_6_capacity_op}</TableCell>
                </TableRow>
              </>
            )}
            {formData.no_of_chillers_op >= 7 && (
              <>
                <TableRow>
                  <TableCell>Capacity Of Chiller 7 Op</TableCell>
                  <TableCell>{formData.chiller_7_capacity_op}</TableCell>
                </TableRow>
              </>
            )}
            {formData.no_of_chillers_op >= 8 && (
              <>
                <TableRow>
                  <TableCell>Capacity Of Chiller 8 Op</TableCell>
                  <TableCell>{formData.chiller_8_capacity_op}</TableCell>
                </TableRow>
              </>
            )}
            {formData.no_of_chillers_op >= 9 && (
              <>
                <TableRow>
                  <TableCell>Capacity Of Chiller 9 Op</TableCell>
                  <TableCell>{formData.chiller_9_capacity_op}</TableCell>
                </TableRow>
              </>
            )}
            {formData.no_of_chillers_op >= 10 && (
              <>
                <TableRow>
                  <TableCell>Capacity Of Chiller 10 Op</TableCell>
                  <TableCell>{formData.chiller_10_capacity_op}</TableCell>
                </TableRow>
              </>
            )}
            <TableRow>
              {/* <TableCell>Nominal COP Op (Kw/kw)</TableCell> */}
              <TableCell> {`Nominal COP Op (${dimensions})`} </TableCell>
              <TableCell>{formData.nominal_cop_op}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell> {`Minimum Startup Capacity Op (${dimensions})`}</TableCell>
              {/* <TableCell>Minimum Startup Capacity Op(Kw/kw)</TableCell> */}
              <TableCell>{formData.minimum_start_up_capacity_op}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Box
        sx={{
          textAlign: "center",
          color: "#FFFF00",
          backgroundColor: "#68B90B",
          padding: "10px",
fontSize: "18px",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        TRHs Information
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableBody>
          <TableRow>
              <TableCell sx={{width: "700px"}}>No Of TRHs</TableCell>
              <TableCell>{formData.no_of_trhs_op}</TableCell>
            </TableRow>
          {formData.no_of_trhs_op >= 1 && (
              <>
                <TableRow>
                  <TableCell>Capacity Of TRHs 1</TableCell>
                  <TableCell>{formData.capacity_of_trhs_op}</TableCell>
                </TableRow>
              </>
            )}
            {formData.no_of_trhs_op >= 2 && (
              <>
                <TableRow>
                  <TableCell>Capacity Of TRHs 2</TableCell>
                  <TableCell>{formData.capacity_of_trhs_2_op}</TableCell>
                </TableRow>
              </>
            )}
            {formData.no_of_trhs_op >= 3 && (
              <>
                <TableRow>
                  <TableCell>Capacity Of TRHs 3</TableCell>
                  <TableCell>{formData.capacity_of_trhs_3_op}</TableCell>
                </TableRow>
              </>
            )}
            {formData.no_of_trhs_op >= 4 && (
              <>
                <TableRow>
                  <TableCell>Capacity Of TRHs 4</TableCell>
                  <TableCell>{formData.capacity_of_trhs_4_op}</TableCell>
                </TableRow>
              </>
            )}
            {formData.no_of_trhs_op >= 5 && (
              <>
                <TableRow>
                  <TableCell>Capacity Of TRHs 5</TableCell>
                  <TableCell>{formData.capacity_of_trhs_5_op}</TableCell>
                </TableRow>
              </>
            )}
            {formData.no_of_trhs_op >= 6 && (
              <>
                <TableRow>
                  <TableCell>Capacity Of TRHs 6</TableCell>
                  <TableCell>{formData.capacity_of_trhs_6_op}</TableCell>
                </TableRow>
              </>
            )}
            {formData.no_of_trhs_op >= 7 && (
              <>
                <TableRow>
                  <TableCell>Capacity Of TRHs 7</TableCell>
                  <TableCell>{formData.capacity_of_trhs_7_op}</TableCell>
                </TableRow>
              </>
            )}
            {formData.no_of_trhs_op >= 8 && (
              <>
                <TableRow>
                  <TableCell>Capacity Of TRHs 8</TableCell>
                  <TableCell>{formData.capacity_of_trhs_8_op}</TableCell>
                </TableRow>
              </>
            )}
            {formData.no_of_trhs_op >= 9 && (
              <>
                <TableRow>
                  <TableCell>Capacity Of TRHs 9</TableCell>
                  <TableCell>{formData.capacity_of_trhs_9_op}</TableCell>
                </TableRow>
              </>
            )}
            {formData.no_of_trhs_op >= 10 && (
              <>
                <TableRow>
                  <TableCell>Capacity Of TRHs 10</TableCell>
                  <TableCell>{formData.capacity_of_trhs_10_op}</TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box
        sx={{
          textAlign: "center",
          color: "#FFFF00",
          backgroundColor: "#68B90B",
          padding: "10px",
fontSize: "18px",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        Thermal Store Information
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell sx={{width: "700px"}}>Is Thermal Store Available?</TableCell>
              <TableCell>{formData.is_thermal_store_available_op}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Thermal Store Capacity</TableCell>
              <TableCell>{formData.thermal_store_capacity_op}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Box
        sx={{
          textAlign: "center",
          color: "#FFFF00",
          backgroundColor: "#68B90B",
          padding: "10px",
                  fontSize: "18px",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        Pumps Information
      </Box>

      <Grid container spacing={2}>
        <Grid item sm={6}>
          {formData.no_of_primary_pumps_op > 0 && (
            <Box
              sx={{
                textAlign: "center",
                color: "#FFFF00",
                fontSize: "16px",
                padding: "1px",
              }}
            >
              <h3>Primary Pumps</h3>
            </Box>
          )}
          <TableContainer component={Paper}>
            <>
              <Table>
                <TableBody>
                  {formData.no_of_primary_pumps_op > 0 &&
                    formData.primary_pump_type_op && (
                      <>
                        <TableRow>
                          <TableCell>Primary Pump Type Op</TableCell>
                          <TableCell>{formData.primary_pump_type_op}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>No Of Primary Pumps</TableCell>
                          <TableCell>
                            {formData.no_of_primary_pumps_op}
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  {formData.no_of_primary_pumps_op >= 1 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Primary Op</TableCell>
                        <TableCell>
                          {formData.capacity_of_primary_pumps_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_primary_pumps_op >= 2 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Primary Op 2</TableCell>
                        <TableCell>
                          {formData.capacity_of_primary_pumps_2_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_primary_pumps_op >= 3 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Primary Op 3</TableCell>
                        <TableCell>
                          {formData.capacity_of_primary_pumps_3_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_primary_pumps_op >= 4 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Primary Op 4</TableCell>
                        <TableCell>
                          {formData.capacity_of_primary_pumps_4_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_primary_pumps_op >= 5 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Primary Op 5</TableCell>
                        <TableCell>
                          {formData.capacity_of_primary_pumps_5_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_primary_pumps_op >= 6 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Primary Op 6</TableCell>
                        <TableCell>
                          {formData.capacity_of_primary_pumps_6_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_primary_pumps_op >= 7 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Primary Op 7</TableCell>
                        <TableCell>
                          {formData.capacity_of_primary_pumps_7_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_primary_pumps_op >= 8 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Primary Op 8</TableCell>
                        <TableCell>
                          {formData.capacity_of_primary_pumps_8_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_primary_pumps_op >= 9 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Primary Op 9</TableCell>
                        <TableCell>
                          {formData.capacity_of_primary_pumps_9_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_primary_pumps_op >= 10 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Primary Op 10</TableCell>
                        <TableCell>
                          {formData.capacity_of_primary_pumps_10_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </>
          </TableContainer>
        </Grid>
        <Grid item sm={6}>
          {formData.no_of_secondary_pumps_op > 0 && (
            <Box
              sx={{
                textAlign: "center",
                color: "#FFFF00",
                fontSize: "16px",
                padding: "1px",
              }}
            >
              <h3>Secondary Pumps</h3>
            </Box>
          )}
          <TableContainer component={Paper}>
            <>
              <Table>
                <TableBody>
                  {formData.no_of_secondary_pumps_op >0 &&
                    formData.secondary_pump_type_op && (
                      <>
                        <TableRow>
                          <TableCell>Secondary Pump Type </TableCell>
                          <TableCell>
                            {formData.secondary_pump_type_op}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>No Of Secondary Pumps </TableCell>
                          <TableCell>
                            {formData.no_of_secondary_pumps_op}
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  {formData.no_of_secondary_pumps_op >= 1 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Secondary Pump 1</TableCell>
                        <TableCell>
                          {formData.capacity_of_secondary_pumps_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_secondary_pumps_op >= 2 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Secondary Pump 2</TableCell>
                        <TableCell>
                          {formData.capacity_of_secondary_pumps_2_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_secondary_pumps_op >= 3 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Secondary Pump 3</TableCell>
                        <TableCell>
                          {formData.capacity_of_secondary_pumps_3_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_secondary_pumps_op >= 4 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Secondary Pump 4</TableCell>
                        <TableCell>
                          {formData.capacity_of_secondary_pumps_4_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_secondary_pumps_op >= 5 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Secondary Pump 5</TableCell>
                        <TableCell>
                          {formData.capacity_of_secondary_pumps_5_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_secondary_pumps_op >= 6 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Secondary Pump 6</TableCell>
                        <TableCell>
                          {formData.capacity_of_secondary_pumps_6_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_secondary_pumps_op >= 7 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Secondary Pump 7</TableCell>
                        <TableCell>
                          {formData.capacity_of_secondary_pumps_7_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_secondary_pumps_op >= 8 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Secondary Pump 8</TableCell>
                        <TableCell>
                          {formData.capacity_of_secondary_pumps_8_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_secondary_pumps_op >= 9 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Secondary Pump 9</TableCell>
                        <TableCell>
                          {formData.capacity_of_secondary_pumps_9_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_secondary_pumps_op >= 10 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Secondary Pump 10</TableCell>
                        <TableCell>
                          {formData.capacity_of_secondary_pumps_10_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </>
          </TableContainer>
        </Grid>
        <Grid item sm={6}>
          {formData.no_of_condenser_pumps_op > 0 && (
            <Box
              sx={{
                textAlign: "center",
                color: "#FFFF00",
                fontSize: "16px",
                padding: "1px",
              }}
            >
              <h3>Condenser Pumps</h3>
            </Box>
          )}
          <TableContainer component={Paper}>
            <>
              <Table>
                <TableBody>
                  {formData.no_of_condenser_pumps_op > 0 && 
                    formData.condenser_pump_type_op && (
                      <>
                        <TableRow>
                          <TableCell>Condenser Pump Type </TableCell>
                          <TableCell>
                            {formData.condenser_pump_type_op}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>No Of Condenser Pumps </TableCell>
                          <TableCell>
                            {formData.no_of_condenser_pumps_op}
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  {formData.no_of_condenser_pumps_op >= 1 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Condenser 1</TableCell>
                        <TableCell>
                          {formData.capacity_of_condenser_pumps_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_condenser_pumps_op >= 2 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Condenser 2</TableCell>
                        <TableCell>
                          {formData.capacity_of_condenser_pumps_2_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_condenser_pumps_op >= 3 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Condenser 3</TableCell>
                        <TableCell>
                          {formData.capacity_of_condenser_pumps_3_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_condenser_pumps_op >= 4 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Condenser 4</TableCell>
                        <TableCell>
                          {formData.capacity_of_condenser_pumps_4_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_condenser_pumps_op >= 5 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Condenser 5</TableCell>
                        <TableCell>
                          {formData.capacity_of_condenser_pumps_5_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_condenser_pumps_op >= 6 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Condenser 6</TableCell>
                        <TableCell>
                          {formData.capacity_of_condenser_pumps_6_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_condenser_pumps_op >= 7 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Condenser 7</TableCell>
                        <TableCell>
                          {formData.capacity_of_condenser_pumps_7_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_condenser_pumps_op >= 8 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Condenser 8</TableCell>
                        <TableCell>
                          {formData.capacity_of_condenser_pumps_8_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_condenser_pumps_op >= 9 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Condenser 9</TableCell>
                        <TableCell>
                          {formData.capacity_of_condenser_pumps_9_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_condenser_pumps_op >= 10 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Condenser 10</TableCell>
                        <TableCell>
                          {formData.capacity_of_condenser_pumps_10_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </>
          </TableContainer>
        </Grid>
        <Grid item sm={6}>
          {formData.no_of_tertiary_pumps_op > 0 && (
            <Box
              sx={{
                textAlign: "center",
                color: "#FFFF00",
                fontSize: "16px",
                padding: "1px",
              }}
            >
              <h3>Tertiary Pumps</h3>
            </Box>
          )}
          <TableContainer component={Paper}>
            <>
              <Table>
                <TableBody>
                  {formData.no_of_tertiary_pumps_op > 0 &&
                    formData.tertiary_pump_type_op && (
                      <>
                        <TableRow>
                          <TableCell>Tertiary Pump Type Op</TableCell>
                          <TableCell>
                            {formData.tertiary_pump_type_op}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>No Of Tertiary Pumps</TableCell>
                          <TableCell>
                            {formData.no_of_tertiary_pumps_op}
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  {formData.no_of_tertiary_pumps_op >= 1 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Tertiary Pump 1</TableCell>
                        <TableCell>
                          {formData.capacity_of_tertiary_pumps_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_tertiary_pumps_op >= 2 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Tertiary Pump 2</TableCell>
                        <TableCell>
                          {formData.capacity_of_tertiary_pumps_2_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_tertiary_pumps_op >= 3 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Tertiary Pump 3</TableCell>
                        <TableCell>
                          {formData.capacity_of_tertiary_pumps_3_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_tertiary_pumps_op >= 4 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Tertiary Pump 4</TableCell>
                        <TableCell>
                          {formData.capacity_of_tertiary_pumps_4_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_tertiary_pumps_op >= 5 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Tertiary Pump 5</TableCell>
                        <TableCell>
                          {formData.capacity_of_tertiary_pumps_5_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_tertiary_pumps_op >= 6 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Tertiary Pump 6</TableCell>
                        <TableCell>
                          {formData.capacity_of_tertiary_pumps_6_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_tertiary_pumps_op >= 7 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Tertiary Pump 7</TableCell>
                        <TableCell>
                          {formData.capacity_of_tertiary_pumps_7_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_tertiary_pumps_op >= 8 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Tertiary Pump 8</TableCell>
                        <TableCell>
                          {formData.capacity_of_tertiary_pumps_8_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_tertiary_pumps_op >= 9 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Tertiary Pump 9</TableCell>
                        <TableCell>
                          {formData.capacity_of_tertiary_pumps_9_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_tertiary_pumps_op >= 10 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Tertiary Pump 10</TableCell>
                        <TableCell>
                          {formData.capacity_of_tertiary_pumps_10_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </>
          </TableContainer>
        </Grid>
        <Grid item sm={6}>
          {formData.no_of_cooling_towers_op > 0 && (
            <Box
              sx={{
                textAlign: "center",
                color: "#FFFF00",
                fontSize: "16px",
                padding: "1px",
              }}
            >
              <h3>Cooling Tower Information</h3>
            </Box>
          )}
          <TableContainer component={Paper}>
            <>
              <Table>
                <TableBody>
                  {formData.no_of_cooling_towers_op > 0 &&
                    formData.cooling_tower_type_op && (
                      <>
                        <TableRow>
                          <TableCell>Cooling Tower Type </TableCell>
                          <TableCell>
                            {formData.cooling_tower_type_op}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>No Of Cooling Towers </TableCell>
                          <TableCell>
                            {formData.no_of_cooling_towers_op}
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  {formData.no_of_cooling_towers_op >= 1 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Cooling Tower 1</TableCell>
                        <TableCell>
                          {formData.capacity_of_cooling_towers_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_cooling_towers_op >= 2 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Cooling Tower 2</TableCell>
                        <TableCell>
                          {formData.capacity_of_cooling_towers_2_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_cooling_towers_op >= 3 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Cooling Tower 3</TableCell>
                        <TableCell>
                          {formData.capacity_of_cooling_towers_3_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_cooling_towers_op >= 4 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Cooling Tower 4</TableCell>
                        <TableCell>
                          {formData.capacity_of_cooling_towers_4_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_cooling_towers_op >= 5 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Cooling Tower 5</TableCell>
                        <TableCell>
                          {formData.capacity_of_cooling_towers_5_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_cooling_towers_op >= 6 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Cooling Tower 6</TableCell>
                        <TableCell>
                          {formData.capacity_of_cooling_towers_6_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_cooling_towers_op >= 7 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Cooling Tower 7</TableCell>
                        <TableCell>
                          {formData.capacity_of_cooling_towers_7_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_cooling_towers_op >= 8 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Cooling Tower 8</TableCell>
                        <TableCell>
                          {formData.capacity_of_cooling_towers_8_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_cooling_towers_op >= 9 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Cooling Tower 9</TableCell>
                        <TableCell>
                          {formData.capacity_of_cooling_towers_9_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_cooling_towers_op >= 10 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Cooling Tower 10</TableCell>
                        <TableCell>
                          {formData.capacity_of_cooling_towers_10_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </>
          </TableContainer>
        </Grid>
        <Grid item sm={6}>
          {formData.is_renewal_energy_applicable_op === "Yes" && (
            <Box
              sx={{
                textAlign: "center",
                color: "#FFFF00",
                fontSize: "16px",
                padding: "1px",
              }}
            >
              <h3>Renewable Energy Information</h3>
            </Box>
          )}
          <TableContainer component={Paper}>
            <>
              <Table>
                <TableBody>
                  {formData.is_renewal_energy_applicable_op === "Yes" && (
                    <>
                      <TableRow>
                        <TableCell>Is Renewal Energy Applicable</TableCell>
                        <TableCell>
                          {formData.is_renewal_energy_applicable_op}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Renewal Energy Generated</TableCell>
                        <TableCell>
                          {formData.is_renewal_energy_generated_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </>
          </TableContainer>
        </Grid>

        <Grid item sm={6}>
          {formData.is_submeter_available_op === "Yes" && (
            <Box
              sx={{
                textAlign: "center",
                color: "#FFFF00",
                fontSize: "16px",
                padding: "1px",
              }}
            >
              <h3>Sub-metering Information</h3>
            </Box>
          )}
          <TableContainer component={Paper}>
            <>
              <Table>
                <TableBody>
                  {formData.is_submeter_available_op === "Yes" && (
                    <>
                      <TableRow>
                        <TableCell>Is Submeter Available Op</TableCell>
                        <TableCell>
                          {formData.is_submeter_available_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </>
          </TableContainer>

          {formData.is_submeter_available_op === "Yes" && (
            <Box sx={{ color: "#FFFF00", fontSize: "16px", padding: "1px" }}>
              <h3>Primary Pump</h3>
            </Box>
          )}
          <TableContainer component={Paper}>
            <>
              <Table>
                <TableBody>
                  {formData.is_submeter_available_op === "Yes" && (
                    <>
                      <TableRow>
                        <TableCell>
                          Sub Meter Primary Pump Power Input
                        </TableCell>
                        <TableCell>
                          {formData.sub_meter_primary_pump_power_input}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </>
          </TableContainer>
          {formData.is_submeter_available_op === "Yes" && (
            <Box sx={{ color: "#FFFF00", fontSize: "16px", padding: "1px" }}>
              <h3>Secondary Pump</h3>
            </Box>
          )}
          <TableContainer component={Paper}>
            <>
              <Table>
                <TableBody>
                  {formData.is_submeter_available_op === "Yes" && (
                    <>
                      <TableRow>
                        <TableCell>
                          Sub Meter Secondary Pump Power Input
                        </TableCell>
                        <TableCell>
                          {formData.sub_meter_secondary_pump_power_input}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </>
          </TableContainer>

          {formData.is_submeter_available_op === "Yes" && (
            <Box sx={{ color: "#FFFF00", fontSize: "16px", padding: "1px" }}>
              <h3>Tertiary Pump</h3>
            </Box>
          )}
          <TableContainer component={Paper}>
            <>
              <Table>
                <TableBody>
                  {formData.is_submeter_available_op === "Yes" && (
                    <>
                      <TableRow>
                        <TableCell>
                          Sub Meter Tertiary Pump Power Input
                        </TableCell>
                        <TableCell>
                          {formData.sub_meter_tertiary_pump_power_input}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </>
          </TableContainer>

          {formData.is_submeter_available_op === "Yes" && (
            <Box sx={{ color: "#FFFF00", fontSize: "16px", padding: "1px" }}>
              <h3>Condenser Pump</h3>
            </Box>
          )}
          <TableContainer component={Paper}>
            <>
              <Table>
                <TableBody>
                  {formData.is_submeter_available_op === "Yes" && (
                    <>
                      <TableRow>
                        <TableCell>
                          Sub Meter Condenser Pump Power Input
                        </TableCell>
                        <TableCell>
                          {formData.sub_meter_condenser_pump_power_input}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </>
          </TableContainer>

          {formData.is_submeter_available_op === "Yes" && (
            <Box sx={{ color: "#FFFF00", fontSize: "16px", padding: "1px" }}>
              <h3>Cooling Tower</h3>
            </Box>
          )}
          <TableContainer component={Paper}>
            <>
              <Table>
                <TableBody>
                  {formData.is_submeter_available_op === "Yes" && (
                    <>
                      <TableRow>
                        <TableCell>
                          Sub Meter Cooling Tower Power Input
                        </TableCell>
                        <TableCell>
                          {formData.sub_meter_cooling_tower_power_input}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </>
          </TableContainer>

          {formData.is_submeter_available_op === "Yes" && (
            <Box sx={{ color: "#FFFF00", fontSize: "16px", padding: "1px" }}>
              <h3>Chiller Information</h3>
            </Box>
          )}
          <TableContainer component={Paper}>
            <>
              <Table>
                <TableBody>
                  {formData.is_submeter_available_op === "Yes" && (
                    <>
                      <TableRow>
                        <TableCell>Sub Meter Chiller Information</TableCell>
                        <TableCell>
                          {formData.sub_meter_chiller_infomation}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </>
          </TableContainer>

          {formData.is_submeter_available_op === "Yes" && (
            <Box sx={{ color: "#FFFF00", fontSize: "16px", padding: "1px" }}>
              <h3>Thermal Store Information</h3>
            </Box>
          )}
          <TableContainer component={Paper}>
            <>
              <Table>
                <TableBody>
                  {formData.is_submeter_available_op === "Yes" && (
                    <>
                      <TableRow>
                        <TableCell>
                          Sub Meter Thermal Store Information
                        </TableCell>
                        <TableCell>
                          {formData.sub_meter_termal_store_infomation}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </>
          </TableContainer>

          {formData.is_submeter_available_op === "Yes" && (
            <Box sx={{ color: "#FFFF00", fontSize: "16px", padding: "1px" }}>
              <h3>Renewable energy Information</h3>
            </Box>
          )}
          <TableContainer component={Paper}>
            <>
              <Table>
                <TableBody>
                  {formData.is_submeter_available_op === "Yes" && (
                    <>
                      <TableRow>
                        <TableCell>
                          Sub Meter Renewal Energy Infomation
                        </TableCell>
                        <TableCell>
                          {formData.sub_meter_renewal_energy_infomation}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </>
          </TableContainer>
        </Grid>
        <Grid item sm={6}>
          {formData.no_of_yearly_power_consumption_op > 0 && (
            <Box
              sx={{
                textAlign: "center",
                color: "#FFFF00",
                fontSize: "16px",
                padding: "1px",
              }}
            >
              <h3>Yearly Power Consumption</h3>
            </Box>
          )}
          <TableContainer component={Paper}>
            <>
              <Table>
                <TableBody>
                  {formData.no_of_yearly_power_consumption_op >= 1 && (
                    <>
                      <TableRow>
                        <TableCell>No Of Yearly Power Consumption</TableCell>
                        <TableCell>
                          {formData.no_of_yearly_power_consumption_op}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Power Consumption Per Years</TableCell>
                        <TableCell>
                          {formData.power_consumption_per_years_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_yearly_power_consumption_op >= 2 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Tertiary Pump 2</TableCell>
                        <TableCell>
                          {formData.power_consumption_per_years_2_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_yearly_power_consumption_op >= 3 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Tertiary Pump 3</TableCell>
                        <TableCell>
                          {formData.power_consumption_per_years_3_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_yearly_power_consumption_op >= 4 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Tertiary Pump 4</TableCell>
                        <TableCell>
                          {formData.power_consumption_per_years_4_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_yearly_power_consumption_op >= 5 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Tertiary Pump 5</TableCell>
                        <TableCell>
                          {formData.power_consumption_per_years_5_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_yearly_power_consumption_op >= 6 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Tertiary Pump 6</TableCell>
                        <TableCell>
                          {formData.power_consumption_per_years_6_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_yearly_power_consumption_op >= 7 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Tertiary Pump 7</TableCell>
                        <TableCell>
                          {formData.power_consumption_per_years_7_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_yearly_power_consumption_op >= 8 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Tertiary Pump 8</TableCell>
                        <TableCell>
                          {formData.power_consumption_per_years_8_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_yearly_power_consumption_op >= 9 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Tertiary Pump 9</TableCell>
                        <TableCell>
                          {formData.power_consumption_per_years_9_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {formData.no_of_yearly_power_consumption_op >= 10 && (
                    <>
                      <TableRow>
                        <TableCell>Capacity Of Tertiary Pump 10</TableCell>
                        <TableCell>
                          {formData.power_consumption_per_years_10_op}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
}
