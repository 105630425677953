import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import RedrawRadar from "./redrawRadar";
import "./graph.css";

const colorLegend = [
  "#f77f00",
  "#219ebc",
  "#8ecae6",
  "#bc6c25",
  "#dda15e",
  "#43aa8b",
  "#90be6d",
  "#d62828",
  "#fff700",
];

const Redrawgraph = ({ solarData }) => {
  // console.log("redraw data:::::", solarData);

  const svgRef = useRef(null);

  useEffect(() => {
    const margin = { top: 10, right: 30, bottom: 30, left: 60 };
    const width = 1200 - margin.left - margin.right;
    const height = 450 - margin.top - margin.bottom;

    const svg = d3
      .select(svgRef.current)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const x = d3
      .scaleBand()
      .range([0, width])
      .domain(months)
      .paddingInner(5)
      .paddingOuter(5);

    svg
      .append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(x));

    const directions = {
      NE: -135,
      E: -90,
      SE: -45,
      S: 0,
      SW: 45,
      W: 90,
      NW: 135,
      N: 180,
    };

    let legendData = Object.keys(directions).map((k, id) => {
      return { label: k, color: colorLegend[id] };
    });

    legendData = [...legendData, { label: "Top", color: colorLegend[8] }];

    const legendContainer = d3
      .select("#legend-container")
      .append("svg")
      // .attr("color", "white")
      .attr("width", width + margin.left + margin.right)
      .attr("height", 20 + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    const legend = legendContainer
      .append("g")
      .selectAll("slots")
      .data(legendData)
      .enter();

    legend
      .append("rect")
      .attr("width", 20)
      .attr("height", 20)
      .attr("fill", (d) => d.color)
      .attr("x", (_d, id) => id * 80);

    legend
      .append("text")
      .attr("class", "direction-label")
      .attr("x", (_d, id) => id * 80 + 25)
      .attr("y", 15)
      .text((d) => d.label)
      .style("fill", (d) => d.color);

    const y = d3
      .scaleLinear()
      .domain([0, d3.max(solarData.flat())])
      .range([height, 0]);

    svg.append("g").classed("yAxis", true).call(d3.axisLeft(y));

    for (let i = 0; i < 9; i++) {
      svg
        .append("path")
        .datum(solarData)
        .classed("smoothCrv", true)
        .attr("fill", "none")
        .attr("stroke", colorLegend[i])
        .attr("stroke-width", 3)
        .attr(
          "d",
          d3
            .line()
            .curve(d3.curveCatmullRom)
            .x((_d, id) => x(months[id]))
            .y((d) => y(d[i]))
        );
    }

    const Tooltip = d3
      .select("#my_dataviz")
      .append("div")
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "white")
      .style("border", "solid")
      .style("border-width", "2px")
      .style("border-radius", "5px")
      .style("padding", "5px");

    const mouseover = function (d) {
      Tooltip.style("opacity", 1);
    };

    const mousemove = function (d, i) {
      if (Tooltip.node()) {
        Tooltip.style("opacity", 0);

        Tooltip.html(
          "Direction: " +
            (i !== 8 ? Object.keys(directions)[i] : "Top") +
            " - Exact value: " +
            d[i]
        );

        const cells = Array.from(document.querySelectorAll(".details tr"))
          .slice(1)
          .map((row) => Array.from(row.children)[i + 1]);

        cells.forEach((cell) => {
          cell.style.backgroundColor = "#B3B0DE";
        });

        const mainCell = document.querySelector(
          `.details tr:nth-child(${solarData.indexOf(d) + 2}) td:nth-child(${
            i + 2
          })`
        );
        mainCell.style.backgroundColor = "lightsteelblue";
        mainCell.style.boxShadow = "0px 0px 15px black";
      }
    };

    const mouseleave = function (d, i) {
      if (Tooltip.node()) {
        Tooltip.style("opacity", 0);

        const cells = Array.from(document.querySelectorAll(".details tr"))
          .slice(1)
          .map((row) => Array.from(row.children)[i + 1]);

        cells.forEach((cell) => {
          cell.style.backgroundColor = "steelblue";
        });

        const mainCell = document.querySelector(
          `.details tr:nth-child(${solarData.indexOf(d) + 2}) td:nth-child(${
            i + 2
          })`
        );
        mainCell.style.boxShadow = "none";
      }
    };

    for (let i = 0; i < 9; i++) {
      svg
        .append("g")
        .classed("dots", true)
        .selectAll("dot")
        .attr("class", "direction-line")
        .data(solarData)
        .enter()
        .append("circle")
        .attr("class", "myCircle")
        .attr("cx", (_d, id) => x(months[id]))
        .attr("cy", (d) => y(d[i]))
        .attr("r", 1.5)
        .attr("stroke", colorLegend[i])
        .attr("stroke-width", 3)
        .attr("fill", "white")
         // .on("mouseover", mouseover)
        // .on("mousemove", (d) => mousemove(d, i))
        // .on("mouseleave", (d) => mouseleave(d, i));
    }
  }, [solarData]);

  return (
    <div>
      <RedrawRadar data={solarData} />

      <div id="my_dataviz" ref={svgRef}></div>
      {/* <divs id="legend-container"></divs> */}
    </div>
  );
};

export default Redrawgraph;
